import classNames from "classnames";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Label from "../form/Label";

function CardShow({cards,index,className}){
    const classnames = classNames('cards '+className)
    const navigate = useNavigate();
    const renderedCards = cards.map((element,index)=>{
        return (
            <FlexColGroup className={'card'} key={index} onClick={()=>{
                navigate('/services/'+element.path)
            }}>
                <div className="colorBend"></div>
                <Image className={'image'} src={element.image}></Image>
                <Label className={'title'}>{element.title}</Label>
            </FlexColGroup>
        )
    })
    return(
        <div className={classnames}>
            <div className={'cardslide '+className} style={{ transform: `translate3d(${-index * (100/(cards.length/3))}%, 0, 0)` }}>
            {renderedCards}
            </div>
        </div>
    )
}
export default CardShow;