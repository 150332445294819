import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/industry/FirstSection";
import SecondSection from "../../components/industry/SecondSection";
import "../../assets/css/service.css";
import ThirdSection from "../../components/industry/ThirdSection";
import ForthSection from "../../components/industry/ForthSection";
import FifthSection from "../../components/industry/FifthSection";
import FirstSectionImage from "../../assets/images/industry/Agriculture/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function DigitalAgriculture(){
    const card = {
        title : 'Manufacturing',
        description : "Unio Labs provides cutting-edge electronics solutions for the manufacturing industry, helping businesses to streamline their processes and increase efficiency. Our integrated engineering solutions are designed to address complex challenges and improve productivity in manufacturing facilities.",
        isLeft : false,
        image : require('../../assets/images/industry/secondSection/secondSection1.png')
    }
    const infoCard = [{
        title: 'Embedded systems',
        description: 'We specializes in developing and implementing the latest technologies in hardware and software for the manufacturing industry. Our embedded systems provide real-time monitoring and control of critical operations, enabling improved efficiency and productivity.',
        number: '01'
    },{
        title: 'Predictive and preventive maintenance solutions',
        description: "Unio Labs' predictive and preventive maintenance solutions help manufacturers to avoid costly equipment failures and minimize downtime by monitoring equipment performance, detecting anomalies, and identifying potential issues before they occur.",
        number: '02'
    },{
        title: 'Security and performance engineering',
        description: 'We provide comprehensive security and performance engineering solutions that protect against cyber threats and ensure optimal performance of systems and equipment.',
        number: '03'
    },{
        title: 'Integrated engineering solutions',
        description: 'Unio Labs provides integrated engineering solutions that help manufacturers to streamline their operations, reduce costs, and improve overall efficiency. Our team of experts works closely with manufacturers to understand their unique requirements and develop customized solutions that meet their needs.',
        number: '04'
    }]
    return(
        <FlexColGroup className={'industry'}>
            <FirstSection legend={'Industry / Digital Agriculture'} title={'Collaborate with Unio Labs for Smart Agriculture Solutions'} description={'IoT-enabled Farm Monitoring and Control for Improved Resource Utilization'} image={FirstSectionImage}/>
            <SecondSection card={card}/>
            <ThirdSection infoCard={infoCard} title={'Our Diverse Range of Offerings for Manufacturing Excellence'}
                description={'Our offerings in Manufacturing Industry'}
            />
            <ForthSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default DigitalAgriculture;