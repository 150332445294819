import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/industry/FirstSection";
import SecondSection from "../../components/industry/SecondSection";
import "../../assets/css/service.css";
import ThirdSection from "../../components/industry/ThirdSection";
import ForthSection from "../../components/industry/ForthSection";
import FifthSection from "../../components/industry/FifthSection";
import FirstSectionImage from "../../assets/images/industry/EnergyUtilites/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function EnergyUtilites(){
    const card = {
        title : 'Energy and Utilities',
        description : "We provide advanced data analytics solutions to optimize energy and utility operations. Our services include predictive maintenance, energy forecasting, and anomaly detection to improve efficiency and reduce downtime. We also provide real-time monitoring solutions for energy and utility networks to detect and respond to outages and other issues quickly. Additionally, we offer solutions for grid modernization and renewable energy integration, including smart grid technology and energy storage systems.",
        isLeft : false,
        image : require('../../assets/images/industry/EnergyUtilites/secondSection.png')
    }
    const infoCard = [{
        title: 'Predictive maintenance solutions',
        description: 'These solutions use machine learning algorithms and sensors to monitor equipment and predict when maintenance is required. By proactively scheduling maintenance, healthcare providers can minimize disruptions to patient care and avoid costly emergency repairs.',
        number: '01'
    },{
        title: 'Energy forecasting and optimization solutions',
        description: "Unio Labs provides energy forecasting and optimization solutions to healthcare facilities to help them reduce their energy costs while maintaining a comfortable environment for patients and staff. These solutions use data analytics to predict energy usage patterns and optimize energy consumption in real-time.",
        number: '02'
    },{
        title: 'Energy Management System',
        description: 'Energy management system is designed to track and analyze energy consumption data, identify areas of inefficiency, and provide recommendations for improvement.',
        number: '03'
    },{
        title: 'Data management',
        description: 'These solutions use advanced analytics and data visualization tools to provide insights into patient care, operations, and financial performance. By leveraging their data, healthcare organizations can make more informed decisions, optimize their operations, and improve patient outcomes.',
        number: '04'
    }]
    return(
        <FlexColGroup className={'industry'}>
            <FirstSection legend={'Industry / Energy and Utilities'} title={'Collaborate with Unio Labs for Innovative Energy and Utilities Solutions'} description={'Asset Management and Performance Optimization for Improved Energy Efficiency'} image={FirstSectionImage}/>
            <SecondSection card={card}/>
            <ThirdSection infoCard={infoCard} title={'Our Diverse Range of Offerings for Energy and Utilities Excellence'}
                description={'Our offerings in Energy and Utilities Industry'}
            />
            <ForthSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default EnergyUtilites;