import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Form from "../form/Form";
import Label from "../form/Label";
import TextArea from "../form/TextArea";
import Input from "../form/Input";
import Button from "../form/Button";
import { useEffect } from "react";
function FirstSection() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <FlexRowGroup className={"firstSection"}>
      <FlexColGroup className={"content"}>
        <FlexColGroup className={"main"}>
          <Label className={"title"}>Contact us</Label>
          <Label className={"description"}>
            we would love to know more about you and your unique background and
            goals.
          </Label>
        </FlexColGroup>
            <Form className={'form'}>
                <FlexColGroup className={'input'}>
                    <Label className={'text'}>
                        Name
                    </Label>
                    <Input type={'text'} className={'input-field'}/>
                </FlexColGroup>
                <FlexColGroup className={'input'}>
                <Label className={'text'}>
                    Email
                    </Label>
                    <Input type={'text'} className={'input-field'}/>
                </FlexColGroup>
                <FlexColGroup className={'input'}>
                    <Label className={'text'}>
                    Message
                        </Label>
                    <TextArea className={'input-textarea'} placeholder={'Type your message...'}/>
                </FlexColGroup>
                <FlexRowGroup className={'input-checkbox'}>
                    <Input id={'acceptTerm'} type={'checkbox'} className={'checkbox'}/>
                    <Label htmlFor={'acceptTerm'} className={'text'}>I accept the Terms & Conditions</Label>
                </FlexRowGroup>
                <Button className={'button submit-button'} type={'submit'}>
                    Submit
                </Button>
            </Form>
      </FlexColGroup>
      <FlexColGroup className={'info'}>
        <FlexColGroup className={'content'}>
            <FlexColGroup className={'main'}>
                <Label className={'title'}>
                 Address : India
                </Label>
                <Label className={'description'}>
                Ground Floor , H.No. B-48 , Madhura Nagar , Hyderabad , Telangana , India.
                </Label>
            </FlexColGroup>
            <FlexColGroup className={'main'}>
                <Label className={'title'}>
                Contact:
                </Label>
                <Label className={'description'}>
                +91-8886968800 (India)
                contact@uniolabs.com
                </Label>
            </FlexColGroup>
        </FlexColGroup>
        <FlexColGroup className={'content'}>
            <FlexColGroup className={'main'}>
                <Label className={'title'}>
                 Address : United Kingdom
                </Label>
                <Label className={'description'}>
                Kemp House, 152-160 City Road, London, EC1V 2NX, England.                
                </Label>
            </FlexColGroup>
            <FlexColGroup className={'main'}>
                <Label className={'title'}>
                Contact:
                </Label>
                <Label className={'description'}>
                +44-07472718043 (UK)
                contact@uniolabs.com
                </Label>
            </FlexColGroup>
        </FlexColGroup>
      </FlexColGroup>
    </FlexRowGroup>
  );
}
export default FirstSection;
