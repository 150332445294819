import FlexRowGroup from "../components/flex/FlexRowGroup";
import Image from "../components/form/Image";
import Logo from "../assets/images/navbar/logo.svg";
import NavbarContainer from "../components/navbar/NavbarContainer";
function Navbar(){
    return(
        <FlexRowGroup className="navbar">
          <NavbarContainer/>
        </FlexRowGroup>
    );
}
export default Navbar;