import FlexColGroup from "../components/flex/FlexColGroup";
import FlexRowGroup from "../components/flex/FlexRowGroup";
import Image from "../components/form/Image";
import Logo from "../assets/images/navbar/logo.svg";
import Label from "../components/form/Label";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import FacebookIcon from "../assets/images/footer/Icon/Facebook.svg";
import InstagramIcon from "../assets/images/footer/Icon/Instagram.svg";
import LinkedInIcon from "../assets/images/footer/Icon/LinkedIn.svg";
import TwitterIcon from "../assets/images/footer/Icon/Twitter.svg";
import { NavLink } from "react-router-dom";

function Footer() {
    return (
        <FlexColGroup className={'footer'}>
            <FlexRowGroup className={'footer-container'}>
                <FlexColGroup className={'main'}>
                    <Image src={Logo} className={'logo'} />
                    <FlexColGroup className={'footer-contents'}>
                        <Label className={'note'}>Join our newsletter to stay up to date with us.</Label>
                        <FlexRowGroup className={'subscribe-button'}>
                            <Input className={'input'} placeholder={'Enter Your email'}/>
                            <Button className={'button'}>Subscribe</Button>
                        </FlexRowGroup>
                        <Label className={'note'}>By subscribing you agree to with our Privacy Policy and provide consent to receive updates from our company.</Label>

                        
                    </FlexColGroup>
                   
                </FlexColGroup>
                <FlexColGroup className={'footer-right-main'}>
                <NavLink to={'/about'} className={'footer-element'}>About Unio Labs</NavLink>
                <NavLink to={'/privacy-policy'} className={'footer-element'}>Privacy policy</NavLink>
                <NavLink to={'/cookie-policy'} className={'footer-element'}>Cookie policy</NavLink>
                <NavLink to={'/terms-and-condition'} className={'footer-element'}>Terms of use</NavLink>
                <NavLink to={'/portfolio'} className={'footer-element'}>Portfolio</NavLink>
                {/* <NavLink to={'/locate-us'} className={'footer-element'}>Locate Us</NavLink> */}
                <NavLink to={'/contact-us'} className={'footer-element'}>Contact US</NavLink>
                </FlexColGroup>
            </FlexRowGroup>
            <div className="margin"></div>
            <FlexRowGroup className={'credits'}>
                <Label className={'credit-info'}>© 2023 Unio Labs. All rights reserved.</Label>
                <FlexRowGroup className={'social-media'}>
                    <FlexRowGroup className={'icons'}>
                    <Image className={'social-media-icon'} src={FacebookIcon}/>
                    </FlexRowGroup>
                    <FlexRowGroup className={'icons'}>
                    <Image className={'social-media-icon'} src={TwitterIcon}/>
                    </FlexRowGroup>

                    <FlexRowGroup className={'icons'}>
                    <Image className={'social-media-icon'} src={LinkedInIcon}/>
                    </FlexRowGroup>

                    <FlexRowGroup className={'icons'}>
                    <Image className={'social-media-icon'} src={InstagramIcon}/>
                    </FlexRowGroup>
                </FlexRowGroup>
            </FlexRowGroup>
        </FlexColGroup>
    )
}
export default Footer;