import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Button from "../form/Button";
import Image from "../form/Image";
import Label from "../form/Label";
import Linkedln from "../../assets/images/team/Linkedln.svg"
import Twitter from "../../assets/images/team/Twitter.svg"

function Card({Name,Designation,About,Profile}){
    return(
        <FlexRowGroup className={'card'}>
            <Image src={Profile} className={'profile'}></Image>
            <div className="margin"></div>
            <FlexColGroup className={'content'}>
                <FlexColGroup className={'info'}>
                    <Label className={'title'}>{Name}</Label>
                    <Label className={'designation'}>{Designation}</Label>
                </FlexColGroup>
                <Label className={'about'}>{About}</Label>
                <FlexRowGroup className={'icons'}>
{/*                    
                    <Button className={'socialmedia'}>
                        <Image src={Twitter} className={'icon'}></Image>
                    </Button>
                    <Button className={'socialmedia'}>
                        <Image src={Linkedln} className={'icon'}></Image>
                    </Button> */}
                </FlexRowGroup>
            </FlexColGroup>
        </FlexRowGroup>
    )
}
export default Card;