import "../assets/css/sustainability.css";
import LastSection from "../components/discuss/LastSection";
import FlexColGroup from "../components/flex/FlexColGroup";
import FirstSection from "../components/sustainability/FirstSection";
import ForthSection from "../components/sustainability/ForthSection";
import List from "../components/sustainability/List";
import SecondSection from "../components/sustainability/SecondSection";
import ThirdSection from "../components/sustainability/ThirdSection";
function Sustainability(){
    return (
        <FlexColGroup className={'sustainability'}>
            <FirstSection/>
            <SecondSection/>
            <ThirdSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default Sustainability;