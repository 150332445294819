import FlexColGroup from "../flex/FlexColGroup";
import JobCard from "./JobCard";

function SecondSection(){
    return(
        <FlexColGroup className={'secondSection'}>
            <FlexColGroup className={'cards'}>
                    <JobCard jobTitle={"Embedded Systems Engineer"} jobDescription={`
                               •	Design and develop embedded systems architecture for [specific applications or products].
                               •	Collaborate with hardware engineers to integrate software and hardware components.
                               •	Write and optimize embedded software in C, C++, Python.
                               •	Develop and implement real-time algorithms for system functionality.
                               •	Conduct system-level testing and validation to ensure performance and reliability.
                               • 	Debug and troubleshoot issues related to hardware-software integration.
                               •	Document design specifications, test plans, and results.
                               •	Stay up-to-date with industry trends and best practices in embedded systems development.` } location={"Hyderabad"} experience = {"Experience : 5 Years"} JobType={"Full-Time"} DepartmentName={"Research Department"}/>
                    <JobCard jobTitle={"Hardware Design / PCB Design Engineer"} jobDescription={`
                               •	Design and develop hardware components and PCB layouts for [specific products/systems].
                               •	Collaborate with electrical engineers to define system architecture and requirements.
                               •	Select appropriate components and materials for design implementation.
                               •	Perform schematic capture and PCB layout using tools such as Altium Designer.
                               •	Ensure designs meet EMC/EMI, thermal, and manufacturability requirements.
                               •	Conduct design reviews and provide input for improvements.
                               •	Prototype, test, and debug hardware designs.
                               •	Generate documentation including schematics, bill of materials (BOM), and design files.`} location={"Hyderabad"} experience = {"Experience : 4 Years"} JobType={"Full-Time"} DepartmentName={"Research Department"}/>
                    <JobCard jobTitle={"Field Application Engineer"} jobDescription={`
                               •	Serve as the primary technical contact for customers, providing pre-sales and post-sales support.
                               •	Understand customer requirements and recommend suitable products and solutions.
                               •	Conduct product demonstrations, training sessions, and technical presentations.
                               •	Collaborate with sales teams to develop technical proposals and support sales activities.
                               •	Assist in product testing, troubleshooting, and resolving technical issues.
                               •	Provide feedback to engineering teams based on customer needs and experiences.
                               •	Develop and maintain strong relationships with customers to drive customer satisfaction.
                               •	Stay up-to-date with product developments, industry trends, and competitor analysis.`} location={"Hyderabad"} experience = {"Experience : 4 Years"} JobType={"Full-Time"} DepartmentName={"Research Department"}/>
                    <JobCard jobTitle={"Business Development Manager"} jobDescription={`
                               •	Bachelor's/Master's degree in Business Administration, Marketing, or related field.
                               •	10+ years of experience in business development, sales, or related roles.
                               •	Proven track record of achieving sales targets and driving revenue growth.
                               •	Strong understanding of [industry/sector] and market dynamics.
                               •	Excellent communication, negotiation, and presentation skills.
                               •	Ability to build and maintain relationships with clients and partners.
                               •	Strategic thinker with a results-oriented mindset.
                               •	Proficiency in CRM software and Microsoft Office Suite.`} location={"Remote"} experience = {"Experience : 10 Years"} JobType={"Full-Time"} DepartmentName={"Business Department"}/>
                    <JobCard jobTitle={"AI Engineer"} jobDescription={`
                               •	Design and develop AI models and algorithms to solve business challenges.
                               •	Implement and deploy machine learning models into production systems.
                               •	Collaborate with cross-functional teams to gather requirements and design AI solutions.
                               •	Conduct data analysis, preprocessing, and feature engineering.
                               •	Experiment with different machine learning frameworks and libraries.
                               •	Optimize AI models for performance, scalability, and efficiency.
                               •	Stay up-to-date with the latest trends and advancements in AI and machine learning.
                               •	Document and communicate technical concepts and solutions to stakeholders.`} location={"Hyderabad"} experience = {"Experience : 2 - 3 Years"} JobType={"Full-Time"} DepartmentName={"Research Department"}/>
                    <JobCard jobTitle={"Hardware Design Architect"} jobDescription={`
                               •	Define and architect the hardware design for [specific products/systems].
                               •	Lead the development of complex hardware systems from concept to production.
                               •	Collaborate with engineering teams to establish design requirements and constraints.
                               •	Conduct feasibility studies, risk assessments, and trade-off analyses.
                               •	Define system-level architecture, including block diagrams and interfaces.
                               •	Design and implement high-speed digital circuits and interfaces.
                               •	Oversee PCB layout and signal integrity analysis.
                               •	Drive innovation and continuous improvement in hardware design processes.
                               •	Mentor and provide technical guidance to junior engineers.`} location={"Hyderabad"} experience = {"Experience : 6 Years"} JobType={"Full-Time"} DepartmentName={"Research Department"}/>
            </FlexColGroup>
            <FlexColGroup className={'hr-line'}>
            </FlexColGroup>
        </FlexColGroup>
    )
}
export default SecondSection;