import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Image from "../form/Image";
import Label from "../form/Label";

import fifthSection1 from "../../assets/images/aboutus/FifthSection/fifthSection1.svg"
import fifthSection2 from "../../assets/images/aboutus/FifthSection/fifthSection2.svg"
import fifthSection3 from "../../assets/images/aboutus/FifthSection/fifthSection3.svg"
import fifthSection4 from "../../assets/images/aboutus/FifthSection/fifthSection4.svg"
import fifthSection5 from "../../assets/images/aboutus/FifthSection/fifthSection5.svg"
import fifthSection6 from "../../assets/images/aboutus/FifthSection/fifthSection6.svg"
import fifthSection7 from "../../assets/images/aboutus/FifthSection/fifthSection7.svg"
import fifthSection8 from "../../assets/images/aboutus/FifthSection/fifthSection8.svg"
import fifthSection9 from "../../assets/images/aboutus/FifthSection/fifthSection9.svg"
import fifthSection10 from "../../assets/images/aboutus/FifthSection/fifthSection10.svg"
import fifthSection11 from "../../assets/images/aboutus/FifthSection/fifthSection11.svg"
import fifthSection12 from "../../assets/images/aboutus/FifthSection/fifthSection12.svg"
import fifthSection13 from "../../assets/images/aboutus/FifthSection/fifthSection13.svg"
import fifthSection14 from "../../assets/images/aboutus/FifthSection/fifthSection14.svg"
import fifthSection15 from "../../assets/images/aboutus/FifthSection/fifthSection15.svg"
import fifthSection16 from "../../assets/images/aboutus/FifthSection/fifthSection16.svg"

function FifthSection(){
    const noOfImages = [
        fifthSection1,
        fifthSection2,
        fifthSection3,
        fifthSection4,
        fifthSection5,
        fifthSection6,
        fifthSection7,
        fifthSection8,
        fifthSection9,
        fifthSection10,
        fifthSection11,
        fifthSection12,
        fifthSection13,
        fifthSection14,
        fifthSection15,
        fifthSection16
    ];
    const renderedImages = noOfImages.map((element)=>{
        return(
            <Image className={'image'} src={element}/>
        )
    })
    return(
       <FlexColGroup className={'fifthSection'}>
            <Label className={'title'}>
            Our Clients
            </Label>
            <FlexRowGroup className={'images'}>
            {renderedImages}
            </FlexRowGroup>
       </FlexColGroup>

    )
}
export default FifthSection;