import FlexColGroup from "../components/flex/FlexColGroup";
import FirstSection from "../components/portfolio/FirstSection";
import "../assets/css/portfolio.css";
import SecondSection from "../components/portfolio/SecondSection";
import ThirdSection from "../components/portfolio/ThirdSection";
import LastSection from "../components/discuss/LastSection";
function Portfolio(){
    return(
        <FlexColGroup className={'portfolio'}>
            <FirstSection/>
            <SecondSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default Portfolio;