import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";

function FirstSection() {
  return (
    <FlexColGroup className="firstSection">
      <FlexColGroup className={"content"}>
        <FlexColGroup className={'legend'}>
            <Label className={'legend-title'}>Innovation</Label>
            <div className="margin"></div>
        </FlexColGroup>
        <FlexColGroup className={'header'}>
          <div className="margin"></div>
          <Label className={"title"}>
          Transforming ideas into reality through innovation          </Label>
          <Label className={'description'}>
          Innovation is our passion, excellence is our standard.          </Label>
        </FlexColGroup>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default FirstSection;
