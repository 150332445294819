import { useState, useEffect } from "react";
import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";

function FirstSection() {
  const images = [
    require("../../assets/images/home/firstSection/firstSection1.png"),
    require("../../assets/images/home/firstSection/firstSection2.png"),
    require("../../assets/images/home/firstSection/firstSection3.png"),
    require("../../assets/images/home/firstSection/firstSection4.png")
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2500);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <FlexColGroup className="firstSection">
      <div className="slideshow">
        <div
          className="slideshowSlider"
          style={{
            transform: `translate3d(${-selectedIndex * 100}%, 0, 0)`,
            transition: "transform ease 1.2s"
          }}
        >
          {images.map((image, index) => (
            <div
              className="slide"
              key={index}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
      </div>
      <FlexColGroup className={"content"}>
        <FlexColGroup className={"top"}>
          <div className="margin"></div>
          <Label className={"title"}>
            Revolutionizing the world of electronics
          </Label>
        </FlexColGroup>
        <Label className={"description"}>
          At Unio Labs, we are dedicated to revolutionizing the world of
          electronics and bridging the gap between hardware and software. Join
          us on this exciting journey towards a more connected and innovative
          future.
        </Label>
      </FlexColGroup>
      <FlexRowGroup className={"buttons"}>
        {images.map((_, index) => (
          <div
            key={index}
            className={`button ${index === selectedIndex ? "selected" : ""}`}
            onClick={() => setSelectedIndex(index)}
          />
        ))}
      </FlexRowGroup>
    </FlexColGroup>
  );
}

export default FirstSection;
