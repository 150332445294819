import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/service/FirstSection";
import SecondSection from "../../components/service/SecondSection";
import "../../assets/css/industry.css";
import ThirdSection from "../../components/service/ThirdSection";
import ForthSection from "../../components/service/ForthSection";
import FifthSection from "../../components/service/FifthSection";
import SixthSection from "../../components/service/SixthSection";
import FirstSectionImage from "../../assets/images/service/Integrated Engineering Solutions/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function IntegratedEngineeringSolution(){
    const card = {
        title : 'Integrated Engineering Solutions',
        description : "Our Integrated Engineering Solutions involve a holistic approach to engineering that takes into account all aspects of a system, from design and development to testing and maintenance. We work closely with our clients to understand their unique business needs and develop customized solutions that deliver results.",
        isLeft : false,
        image : require('../../assets/images/service/Integrated Engineering Solutions/secondSection.png')
    }
    const infoCard = [{
        title: 'Industrial Automation',
        description: `
        We provide industrial automation solutions that help businesses improve their efficiency and productivity. Our team can design and develop custom automation systems that automate repetitive tasks, reduce manual intervention, and improve overall efficiency.        `,
        number: '01'
    },{
        title: 'Embedded Systems',
        description: 'We offer embedded systems design and development services that help businesses optimize their operations. Our team can design and develop custom embedded systems that meet your specific business needs and integrate seamlessly with your existing systems.',
        number: '02'
    },{
        title: 'Software Developmen',
        description: 'Software Development: We provide comprehensive software development services to help businesses develop custom software solutions that meet their unique needs. Our team can develop software for a wide range of applications, including web and mobile applications, desktop software, and more.',
        number: '03'
    },{
        title: 'Quality Assurance and Testing',
        description: 'We offer comprehensive quality assurance and testing services to ensure that your engineering solutions meet all requirements and deliver the results you need. Our team can perform a range of tests, including functional testing, performance testing, and stress testing, to ensure that your solutions are fully functional and meet all requirements.',
        number: '04'
    }]
    const benefitCards = [
        {
            number : '01',
            text : 'Improved efficiency: Our engineering solutions are designed to optimize operations and improve overall efficiency, helping businesses save time and resources.'
        },
        {
            number : '02',
            text : 'Enhanced productivity: By automating repetitive tasks and reducing manual intervention, our solutions can help businesses improve productivity and output.'
        },{
            number : '03',
            text : 'High quality: We use the latest tools and technologies to ensure that our engineering solutions are of the highest quality and deliver the results our clients need.'
        },{
            number : '04',
            text : 'Competitive advantage: Our engineering solutions can help businesses gain a competitive advantage by improving their operations, productivity, and overall efficiency.'
        }
    ]
    return(
        <FlexColGroup className={'service'}>
            <FirstSection title={'Comprehensive and Efficient Solutions for a Connected World'} legendTitle={'Services / Integrated Engineering Solutions'} description={'Hardware-Software Co-design for Optimal Performance and Resource Utilization'} image={FirstSectionImage}/>
            <SecondSection card={card}/>
            <ThirdSection infoCard={infoCard} 
            title={'Unlock the Potential  with Our Comprehensive Services'}
            description={'Our offerings in Integrated Engineering Solutions'}    
            />
            <ForthSection />
            <SixthSection benefitCards={benefitCards} />
            <LastSection/>
        </FlexColGroup>
    )
}
export default IntegratedEngineeringSolution;