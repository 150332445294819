import FlexRowGroup from "../flex/FlexRowGroup";
import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Label from "../form/Label";
function SecondSection(){
    return(
        <FlexRowGroup className={'secondSection'}>
            <Image alt={'Second Section Image'} className={'image'}/>
            <FlexColGroup className={'content'}>
                <Label className={'title'}>
                Unlocking Potential : Discover<br></br>
                Our<span> Capabilities </span> 
                </Label>
                <Label className={'description'}>
                We emphasize our dedication to breaking barriers and imagining new possibilities while highlighting the importance of adapting to new realities and leveraging the latest technologies to enhance efficiency and sustainability through following services.
                </Label>
            </FlexColGroup>
        </FlexRowGroup>
    )
}
export default SecondSection;