import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Label from "../form/Label";
import DownIcon from "../../assets/images/career/Icon.svg";
import FlexRowGroup from "../flex/FlexRowGroup";
import AccordianQuestion from "./AccordianQuestion";
function Accordian({questions}){
    const renderedQuestion = questions.map((element)=>{
        return (
            <AccordianQuestion question={element} />
        )
    })
    return(
        <FlexColGroup className={"accordian"}>
            {renderedQuestion}
        </FlexColGroup>
    )
}
export default Accordian;