import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/industry/FirstSection";
import SecondSection from "../../components/industry/SecondSection";
import "../../assets/css/service.css";
import ThirdSection from "../../components/industry/ThirdSection";
import ForthSection from "../../components/industry/ForthSection";
import FifthSection from "../../components/industry/FifthSection";
import FirstSectionImage from "../../assets/images/industry/HVAC/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function Logistics() {
  const card = {
    title: "Logistics",
    description:
      "At Unio Labs, we offer logistics solutions for a range of industries, including transportation and supply chain management. We provide a comprehensive suite of data analytics tools that enable us to forecast demand and optimize delivery routes, thereby reducing costs and improving delivery times. Our real-time tracking and monitoring solutions for shipments and inventory enhance visibility and reduce losses. We offer logistics planning and management solutions such as warehouse management and inventory optimization, which help our clients achieve greater efficiency and cost savings.",
    isLeft: false,
    image: require("../../assets/images/industry/HVAC/secondSection.png"),
  };
  const infoCard = [
    {
      title: "Route Optimization Solutions",
      description:
        " We help logistics companies optimize their delivery routes and reduce costs. by using data analytics and machine learning algorithms to analyze delivery data and identify the most efficient delivery routes, reducing fuel consumption and improving delivery times.",
      number: "01",
    },
    {
      title: "Warehouse Management Solutions",
      description:
        "These solutions use IoT sensors and data analytics to monitor inventory levels, optimize warehouse layouts, and improve picking and packing processes, reducing costs and improving customer satisfaction.",
      number: "02",
    },
    {
      title: "Predictive Maintenance Solutions",
      description:
        "We offers predictive maintenance solutions to help logistics companies proactively maintain their fleet and reduce downtime with help of machine learning algorithms and IoT sensors to monitor vehicle health.",
      number: "03",
    },
    {
      title: "Supply Chain Visibility Solutions",
      description:
        "Unio Labs offers supply chain visibility solutions to help logistics companies improve the visibility of their supply chain operations.",
      number: "04",
    },
  ];
  return (
    <FlexColGroup className={"industry"}>
      <FirstSection
        legend={"Industry / Logistics"}
        title={"Collaborate with Unio Labs for Smart Logistics Solutions"}
        description={
          "Smart Logistics Solutions for Improved Supply Chain Efficiency and Visibility"
        }
        image={FirstSectionImage}
      />
      <SecondSection card={card} />
      <ThirdSection
        infoCard={infoCard}
        title={"Our Diverse Range of Offerings for Logistics"}
        description={"Our offerings in logistics Industry"}
      />
      <ForthSection />
      <LastSection />
    </FlexColGroup>
  );
}
export default Logistics;
