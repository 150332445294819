import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";
import ImageList from "./ImagesList";
import Resource from "./Resource";
function ThirdSection(){
    const resources = [
        {
            title : 'Artificial Intelligence',
            description : "Recently we worked on cutting-edge control panel for fabric cutting machines, designed to improve precision and productivity. With advanced automation capabilities and a user-friendly interface, it's the perfect solution for businesses looking to optimize their cutting process.",
            isLeft : false,
            image : require('../../assets/images/sustainability/Resources/resource1.png')
        },{
            title : 'Internet of Things',
            description : "Introducing our high-speed data logger for recording high current and voltage levels in demanding environments. With its precision and reliability, compact design, and user-friendly interface, it's the perfect tool for the automotive, energy, and aerospace industries.",
            isLeft : true,
            image : require('../../assets/images/sustainability/Resources/resource2.png')
        }
    ]
    const renderedResource = resources.map((element)=>{
        return (
            <Resource resource={element}/>
        )
    })
    return(
        <FlexColGroup className={'thirdSection'}>
            <FlexColGroup className={'header'}>
                <FlexRowGroup className={'header-content'}>
                    <div className="margin"></div>
                    <Label className={'title'}>Resources</Label>
                </FlexRowGroup>
            </FlexColGroup>
            <FlexColGroup className={'resources-list'}>
                {renderedResource}
            </FlexColGroup>
            <ImageList/>
        </FlexColGroup>
    )
}
export default ThirdSection;