import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";
import Button from "../form/Button";
import Image from "../form/Image";
import { useState } from "react";
import { useEffect } from "react";
import VisionImage from "../../assets/images/aboutus/ThirdSection/vision.png"
import MissionImage from "../../assets/images/aboutus/ThirdSection/mission.png"
import Vision from "./ThirdSection/Vision";
import Value from "./ThirdSection/Value";
import Journey from "./ThirdSection/Journey";
function ThirdSection() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [component, setComponent] = useState(null);
  const visionCard = {
    image : VisionImage,
    title : `Empowering a Smarter Future with Innovative Electronics`,
    description : `To be the go-to provider of technology solutions for businesses around the world, continuously advancing our offerings to stay at the forefront of innovation.`
}
    const missionCard = {
    image : MissionImage,
    title : `Mission to Drive Transformation through Innovation`,
    description : `"Unio Labs' mission is to innovate through cutting-edge technology and deliver exceptional solutions in embedded systems, custom hardware design, and IoT applications.`
}
    const journeyCard = {
        image : require('../../assets/images/aboutus/ThirdSection/journey.png')
    }
  const buttons = [
    {
      name: "Vision",
      component: <Vision card={visionCard}/>,
    },
    {
      name: "Mission",
      component:  <Vision card={missionCard}/>,
    },
    {
      name: "Values",
      component: <Value/> ,
    },
    {
      name: "Journey",
      component: <Journey card={journeyCard}/>,
    },
  ];

  const onHandleClick = (index, component) => {
    setSelectedIndex(index);
  };
  const renderedButtons = buttons.map((element, index) => {
    return (
      <Label
        className={`button ${index === selectedIndex ? "select" : ""}`}
        onClick={() => {
          onHandleClick(index, element.component);
        }}
      >
        {element.name}
      </Label>
    );
  });

  useEffect(() => {
    setComponent(buttons[selectedIndex].component)
  }, [selectedIndex]);

  return (
    <FlexColGroup className={"thirdSection"}>
      <FlexRowGroup className={"header"}>
        <Label className={"title"}>Our Company’s</Label>
        <FlexRowGroup className={"buttons"}>{renderedButtons}</FlexRowGroup>
      </FlexRowGroup>
      <FlexRowGroup className={"content"}>{component}</FlexRowGroup>
    </FlexColGroup>
  );
}
export default ThirdSection;