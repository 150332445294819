import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/service/FirstSection";
import SecondSection from "../../components/service/SecondSection";
import "../../assets/css/industry.css";
import ThirdSection from "../../components/service/ThirdSection";
import ForthSection from "../../components/service/ForthSection";
import FifthSection from "../../components/service/FifthSection";
import SixthSection from "../../components/service/SixthSection";
import FirstSectionImage from "../../assets/images/service/Predictive and Preventive Maintenance/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function PreventionAndPredictionMaintanence(){
    const card = {
        title : 'Predictive and Preventive Maintenance',
        description : "we understand the importance of maintaining equipment and machinery in optimal condition to ensure maximum up time and prevent unexpected failures. Our Predictive and Preventive Maintenance solutions are designed to help businesses achieve just that. Whether you are looking to optimize the maintenance of your current equipment or implement a predictive maintenance strategy for new equipment, Unio Labs has the expertise and experience to help you achieve your goals.",
        isLeft : false,
        image : require('../../assets/images/service/Predictive and Preventive Maintenance/secondSection.png')
    }
    const infoCard = [{
        title: 'Predictive Maintenance Analytics',
        description: `
        Our team of experts uses advanced analytics and machine learning techniques to analyze data from sensors and other sources to predict potential equipment failures before they occur.        `,
        number: '01'
    },{
        title: 'Preventive Maintenance Planning',
        description: 'Preventive Maintenance Planning: Our team helps businesses develop and implement preventive maintenance strategies to extend the lifespan of their equipment and minimize downtime.',
        number: '02'
    },{
        title: 'Equipment Health Monitoring',
        description: 'We use real-time data to monitor the health of your equipment and alert you of any potential issues or failures.',
        number: '03'
    },{
        title: 'Root Cause Analysis',
        description: 'We identify the root cause of equipment failures and provide recommendations to prevent similar failures from occurring in the future.',
        number: '04'
    }]
    const benefitCards = [
        {
            number : '01',
            text : "Increased Equipment Reliability: With Unio Labs' predictive maintenance analytics and condition monitoring services, customers can identify potential equipment failures before they occur, allowing them to take proactive measures to prevent downtime and equipment damage."
        },
        {
            number : '02',
            text : "Reduced Downtime and Costs: By implementing preventive maintenance strategies and optimizing maintenance practices, Unio Labs helps customers minimize equipment downtime and associated costs, such as repair and replacement expenses."
        },{
            number : '03',
            text : 'Enhanced Safety and Compliance: By ensuring that equipment is maintained to the highest standards, Unio Labs helps customers reduce the risk of accidents and injuries, and maintain compliance with relevant regulations and standards.'
        },{
            number : '04',
            text : 'Improved Asset Performance: With asset performance management services, Unio Labs helps customers optimize the performance of their equipment, resulting in improved production efficiency and reduced waste.'
        }
    ]
    return(
        <FlexColGroup className={'service'}>
            <FirstSection title={'Maximizing Asset Uptime and Reducing Downtime'} legendTitle={'Services / Predictive and Preventive Maintenance'} description={'Condition Monitoring and Health Monitoring for Improved Equipment Reliability'} image={FirstSectionImage}/>
            <SecondSection card={card}/>
            <ThirdSection infoCard={infoCard} 
            title={'Unlock the Potential with Our Comprehensive Services'}
            description={'Our offerings in Predictive and Preventive Maintenance'}    
            />
            <ForthSection />
            <SixthSection benefitCards={benefitCards} />
            <LastSection/>
        </FlexColGroup>
    )
}
export default PreventionAndPredictionMaintanence;