import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/service/FirstSection";
import SecondSection from "../../components/service/SecondSection";
import "../../assets/css/industry.css";
import ThirdSection from "../../components/service/ThirdSection";
import ForthSection from "../../components/service/ForthSection";
import FifthSection from "../../components/service/FifthSection";
import SixthSection from "../../components/service/SixthSection";
import FirstSectionImage from "../../assets/images/service/Iot/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function Iot(){
    const card = {
        title : 'Internet of Things (IOT)',
        description : "Unio Labs specializes in providing IoT solutions that help businesses harness the power of connected devices. Our team of IoT experts can help you design, develop and deploy custom IoT solutions that enable you to collect and analyze data from your connected devices. We offer a range of IoT services, including IoT consulting, IoT development, IoT integration, IoT analytics and IoT security. Our solutions help businesses to reduce costs, increase efficiency and improve customer satisfaction.",
        isLeft : false,
        image : require('../../assets/images/service/Iot/secondSection.png')
    }
    const infoCard = [{
        title: 'IoT Development',
        description: `
        Our IoT development services include designing, developing, and deploying IoT solutions that connect devices and collect data. We can build custom IoT applications and integrations to fit any business need.        `,
        number: '01'
    },{
        title: 'IoT Analytics',
        description: 'With our IoT analytics services, businesses can gain valuable insights from IoT data. Our team can design and implement data analytics solutions that provide real-time visibility into device performance, usage, and other metrics.',
        number: '02'
    },{
        title: 'IoT Security & Maintenance',
        description: `
        We can help with everything from risk assessments to developing and implementing security policies and procedures. Also our IoT maintenance services ensure that IoT systems and devices are always up and running.
        `,
        number: '03'
    },{
        title: 'IoT Integration Services',
        description: 'Unio Labs offers IoT integration services to help businesses connect IoT devices with other systems and applications. Our team can develop custom integrations that allow devices to share data and work seamlessly with other systems.',
        number: '04'
    },{
        title: 'IoT Platform Development',
        description: 'We can help businesses design and build custom IoT platforms that integrate with existing systems and support data collection, analysis, and reporting.',
        number: '05'
    },{
        title: 'IoT Consulting Services',
        description: 'Unio Labs provides expert IoT consulting services to help businesses understand how IoT technologies can benefit their operations. Our team of experts can provide guidance on IoT strategy, implementation, and maintenance.',
        number: '06'
    }]
    const benefitCards = [
        {
            number : '01',
            text : 'Improved customer experience : With IoT, businesses can improve customer experience by providing real-time data, enabling personalized recommendations, and enhancing overall service delivery.'
        },
        {
            number : '02',
            text : 'Improved device uptime : Our IoT maintenance services ensure that connected devices are always up and running, reducing downtime and improving business continuity.'
        },{
            number : '03',
            text : 'Improved operational efficiency : By monitoring device performance and usage, businesses can optimize their operations for maximum efficiency.'
        },{
            number : '04',
            text : 'Improved data insights : By collecting and analyzing data from connected devices, businesses can gain valuable insights into their operations, customer behavior, and market trends.'
        },{
            number : '05',
            text : 'Reduced costs : IoT maintenance services can help businesses avoid costly repairs and replacements by detecting and fixing issues early on.'
        },{
            number : '06',
            text : 'Increased productivity : IoT solutions can automate processes and reduce manual intervention, freeing up time for employees to focus on more critical tasks.'
        }
    ]
    return(
        <FlexColGroup className={'service'}>
            <FirstSection title={'Connecting the Physical and Digital Worlds for Smarter and Safer Environments'} legendTitle={'Services / Internet of Things(IOT)'} description={'Sensor Networks and Data Analytics for Industrial IoT Applications'} image={FirstSectionImage}/>
            <SecondSection card={card}/>
            <ThirdSection infoCard={infoCard} 
            title={'Unlock the Potential  with Our Comprehensive Services'}
            description={'Our offerings in Internet of Things (IOT)'}    
            />
            <ForthSection />
            <SixthSection benefitCards={benefitCards} />
            <LastSection/>
        </FlexColGroup>
    )
}
export default Iot;