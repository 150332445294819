import "../assets/css/home.css"
import FirstSection from "../components/homepages/FirstSection";
import FlexColGroup from "../components/flex/FlexColGroup";
import SecondSection from "../components/homepages/SecondSection";
import ThirdSection from "../components/homepages/ThirdSection";
import FifthSection from "../components/homepages/FifthSection";
import SixthSection from "../components/homepages/SixthSection";
import FourthSection from "../components/homepages/FourthSection";
import LastSection from "../components/discuss/LastSection";
function Home(){
    return(
        <FlexColGroup className={'home'}>
        <FirstSection />
        <SecondSection/>
        <ThirdSection/>
        <FourthSection/>
        <FifthSection/>
        <LastSection/>
        </FlexColGroup>
    )
}
export default Home;