import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/service/FirstSection";
import SecondSection from "../../components/service/SecondSection";
import "../../assets/css/industry.css";
import ThirdSection from "../../components/service/ThirdSection";
import ForthSection from "../../components/service/ForthSection";
import FifthSection from "../../components/service/FifthSection";
import SixthSection from "../../components/service/SixthSection";
import FirstSectionImage from "../../assets/images/service/Digital Twinning/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function DigitalTwinning() {
  const card = {
    title: "Digital Twinning",
    description:
      "Digital twin technology enables businesses to create virtual replicas of their physical assets, processes, and systems, providing real-time insights and predictive analytics. Our digital twinning services empower businesses to optimize their operations, reduce downtime, and improve efficiency. With Unio Labs, you can stay ahead of the curve and embrace the power of digital twinning to transform your industry operations.",
    isLeft: false,
    image: require("../../assets/images/service/Digital Twinning/secondSection.png"),
  };
  const infoCard = [
    {
      title: "Digital Twin Creation",
      description: `
      Unio Labs can create accurate virtual replicas of physical assets and systems, using data from sensors, IoT devices, and other sources. These digital twins provide real-time insights and predictive analytics to help businesses optimize their operations.        `,
      number: "01",
    },
    {
      title: "Process Optimization",
      description:"With digital twinning, Unio Labs can help businesses identify bottlenecks and inefficiencies in their processes, and implement improvements to increase efficiency and productivity.",      
        number: "02",
    },
    {
      title: "Cloud-Based Digital Twins",
      description:
        "Unio Labs can create cloud-based digital twins that enable remote monitoring and management of assets and systems, providing businesses with greater flexibility and scalability.",
      number: "03",
    },
    {
      title: "Integration and Implementation",
      description:
        "Unio Labs can work with businesses to integrate digital twinning technology into their existing systems and processes, ensuring a seamless implementation and maximizing the benefits of this technology.",
      number: "04",
    },
  ];
  const benefitCards = [
    {
      number: "01",
      text: "Increased Operational Efficiency: Digital twin technology provides real-time insights into the performance of physical assets and systems, allowing businesses to optimize their operations and reduce downtime.",
    },
    {
      number: "02",
      text: "Enhanced Productivity: Digital twinning can help businesses identify and address bottlenecks and inefficiencies in their processes, improving productivity and profitability.",
    },
    {
      number: "03",
      text: "Reduced Costs: By enabling proactive maintenance, optimizing operations, and improving productivity, digital twinning can help businesses reduce costs and increase their bottom line.",
    },
    {
      number: "04",
      text: "Enhanced Safety and Security: With digital twinning, businesses can simulate and test scenarios to identify potential safety hazards and security vulnerabilities, improving overall safety and security measures.",
    }
  ];
  return (
    <FlexColGroup className={"service"}>
      <FirstSection
        title={
          "Redefining Industry Operations with Digital Twinning Services"
        }
        legendTitle={"Services / Digital Twinning"}
        description={"Revolutionize your industry operations with Unio Labs' digital twinning services"}
        image={FirstSectionImage}
      />
      <SecondSection card={card} />
      <ThirdSection
        infoCard={infoCard}
        title={"Unlock the Potential  with Our Comprehensive Services"}
        description={"Our offerings in Digital Twinning"}
      />
      <ForthSection />
      <SixthSection benefitCards={benefitCards} />
      <LastSection />
    </FlexColGroup>
  );
}
export default DigitalTwinning;
