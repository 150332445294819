import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/service/FirstSection";
import SecondSection from "../../components/service/SecondSection";
import "../../assets/css/industry.css";
import ThirdSection from "../../components/service/ThirdSection";
import ForthSection from "../../components/service/ForthSection";
import FifthSection from "../../components/service/FifthSection";
import SixthSection from "../../components/service/SixthSection";
import FirstSectionImage from "../../assets/images/service/ArtificalIntelligence/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function ArtificalIntelligence(){
    const card = {
        title : 'Artificial Intelligence',
        description : "AI services are an innovative and exciting way to leverage the power of artificial intelligence to improve business operations and enhance customer experience. With the increasing demand for AI-driven solutions in almost every industry, businesses are now recognizing the potential of AI services to streamline their processes, automate tasks, and gain valuable insights into their data..",
        isLeft : false,
        image : require('../../assets/images/service/ArtificalIntelligence/secondSection1.png')
    }
    const infoCard = [{
        title: 'AI Development',
        description: `
        Our team of experts can help businesses develop custom AI solutions tailored to their specific needs, leveraging the latest technologies and machine learning algorithms.
        `,
        number: '01'
    },{
        title: 'Chatbots and Virtual Assistants',
        description: 'We can create chatbots and virtual assistants that provide quick and personalized responses to customer queries, improving engagement and satisfaction.',
        number: '02'
    },{
        title: 'Predictive Analytics',
        description: 'Our AI services can analyze large amounts of data to provide valuable insights and make predictions, enabling businesses to make informed decisions and gain a competitive advantage.',
        number: '03'
    },{
        title: 'Fraud Detection',
        description: 'We can develop AI-based fraud detection solutions that use machine learning algorithms to identify and prevent fraudulent activities, reducing risks and minimizing losses.',
        number: '04'
    }]
    const benefitCards = [
        {
            number : '01',
            text : 'Improved Efficiency: Our AI-powered solutions can automate repetitive and time-consuming tasks, enabling businesses to optimize their operations and increase efficiency.'
        },
        {
            number : '02',
            text : 'Data-Driven Decision Making: Our services provide valuable insights into business data, enabling companies to make informed decisions and gain a competitive advantage.'
        },{
            number : '03',
            text : 'Enhanced Customer Experience: Our AI-powered chatbots and virtual assistants can improve customer engagement and satisfaction by providing personalized and timely responses to their queries.'
        },{
            number : '04',
            text : 'Increased Accuracy: Our AI services can reduce the risk of human error and improve the accuracy of business operations, leading to better results and cost savings.'
        },{
            number : '05',
            text : 'Cost Savings: By automating tasks and optimizing processes, businesses can save costs on labor and resources, leading to higher profits.'
        }
    ]
    return(
        <FlexColGroup className={'service'}>
            <FirstSection title={'Transform Your Business Operations with Our AI-Powered Solutions'} legendTitle={'Services / Artificial Intelligence'} description={'Revolutionize Your Business with Our AI-Powered Services'} image={FirstSectionImage}/>
            <SecondSection card={card}/>
            <ThirdSection infoCard={infoCard} 
            title={'Unlock the Potential with Our Comprehensive Services'}
            description={'Our offerings in Artificial Intelligence'}    
            />
            <ForthSection />
            <SixthSection benefitCards={benefitCards} />
            <LastSection/>
        </FlexColGroup>
    )
}
export default ArtificalIntelligence;