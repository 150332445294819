import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";
import InfoCard from "./InfoCard";

function ThirdSection({infoCard,title,description}) {
  
    const renderedInfoCards = infoCard.map((element)=>{
        return(
            <InfoCard infoCard={element} />
        )
    })
    return (
        <FlexColGroup className={'thirdSection'}>
                <FlexRowGroup className={'header'}>
                    <div className="margin"></div>
                    <FlexColGroup>
                    <Label className={'title'}>{title}</Label>
                    <Label className={'description'}>{description}</Label>
                    </FlexColGroup>
                </FlexRowGroup>
                <FlexColGroup className={'info-cards'}>
                   {
                    renderedInfoCards
                   }
                </FlexColGroup>
            </FlexColGroup>
    )
}
export default ThirdSection;
