import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/service/FirstSection";
import SecondSection from "../../components/service/SecondSection";
import "../../assets/css/industry.css";
import ThirdSection from "../../components/service/ThirdSection";
import ForthSection from "../../components/service/ForthSection";
import FifthSection from "../../components/service/FifthSection";
import SixthSection from "../../components/service/SixthSection";
import FirstSectionImage from "../../assets/images/service/Computer Vision Based Smart Inspection/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function ComputerVisionBasedSmartInspection() {
  const card = {
    title: "Computer vision based  smart inspection",
    description:
      "Our smart inspection services are highly customization and can be tailored to meet the unique needs of each client. Whether you're in manufacturing, healthcare, or any other industry, Unio Labs smart inspection services can help you improve your quality control processes and boost your bottom line.",
    isLeft: false,
    image: require("../../assets/images/service/Computer Vision Based Smart Inspection/secondSection.png"),
  };
  const infoCard = [
    {
      title: "Automated Inspection",
      description: `
      Unio Labs can develop and implement automated inspection systems that use computer vision technology to inspect products and components for defects and anomalies, reducing the need for manual inspections and improving overall efficiency.
        `,
      number: "01",
    },
    {
      title: "Defect Detection and Classification",
      description:
        "Unio Labs can use computer vision algorithms and machine learning models to accurately detect and classify defects and anomalies in products and components, improving quality control and reducing the risk of product recalls.",
      number: "02",
    },
    {
      title: "Customized Inspection Solutions",
      description:
        "Unio Labs can work with businesses to develop customized inspection solutions tailored to their unique needs and requirements, ensuring that the smart inspection technology provides maximum value and benefit.",
      number: "03",
    },
    {
      title: "Integration with Existing Systems",
      description:
        "Unio Labs can integrate smart inspection technology with existing systems and processes, minimizing disruption and ensuring a seamless implementation.",
      number: "04",
    },
  ];
  const benefitCards = [
    {
      number: "01",
      text: "Improved Product Quality: Unio Labs' smart inspection technology can detect even the smallest defects and anomalies, ensuring that products meet the highest quality standards and reducing the risk of product recalls.",
    },
    {
      number: "02",
      text: "Increased Efficiency: By automating the inspection process, Unio Labs' smart inspection services can significantly increase efficiency, reducing the need for manual inspections and freeing up time for other tasks.",
    },
    {
      number: "03",
      text: "Cost Savings: By improving product quality and reducing the risk of recalls, Unio Labs' smart inspection services can help businesses save money and reduce waste.",
    },
    {
      number: "04",
      text: "Enhanced Safety: Unio Labs' smart inspection technology can identify potential safety hazards and alert businesses to take corrective action, reducing the risk of accidents and injuries.",
    },
    {
      number: "05",
      text: "Data-Driven Decision Making: Unio Labs' smart inspection services provide businesses with real-time data analytics and reporting tools, enabling them to make data-driven decisions to improve quality control processes and reduce costs.",
    },
  ];
  return (
    <FlexColGroup className={"service"}>
      <FirstSection
        title={
          "Digitization in Quality Control with Us"
        }
        legendTitle={"Services / Computer Vision Based Smart Inspection"}
        description={"Transform your quality control process with Unio Labs' advanced computer vision technology"}
        image={FirstSectionImage}
      />
      <SecondSection card={card} />
      <ThirdSection
        infoCard={infoCard}
        title={"Unlock the Potential  with Our Comprehensive Services"}
        description={"Our offerings in Computer vision based  smart inspection"}
      />
      <ForthSection />
      <SixthSection benefitCards={benefitCards} />
      <LastSection />
    </FlexColGroup>
  );
}
export default ComputerVisionBasedSmartInspection;
