import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/service/FirstSection";
import SecondSection from "../../components/service/SecondSection";
import "../../assets/css/industry.css";
import ThirdSection from "../../components/service/ThirdSection";
import ForthSection from "../../components/service/ForthSection";
import FifthSection from "../../components/service/FifthSection";
import SixthSection from "../../components/service/SixthSection";
import FirstSectionImage from "../../assets/images/service/Security and Performance Engineering/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function SecurityAndPerformanceEngineering() {
  const card = {
    title: "Security and Performance Engineering",
    description: "Our Security and Performance Engineering services are tailored to meet the specific needs of each customer, whether they are looking to secure their existing systems or applications, optimize system performance, or develop new systems or applications from the ground up.",
    isLeft: false,
    image: require("../../assets/images/service/Security and Performance Engineering/secondSection.png"),
  };
  const infoCard = [
    {
      title: "Cloud Security Solutions",
      description: `
      Unio Labs helps clients secure their cloud infrastructure and data by providing cloud security assessments, designing and implementing secure cloud architectures, and offering ongoing monitoring and management services.
        `,
      number: "01",
    },
    {
      title: "Expert System Optimization",
      description:
        "Our engineers are experts in system optimization, ensuring that systems and applications are configured to run at peak performance while remaining secure and reliable.",
      number: "02",
    },
    {
      title: "High-Quality Development Services",
      description:
        "Our team of developers has years of experience in developing secure, reliable, and high-performing systems and applications, ensuring that customers receive high-quality development services that meet their specific needs.",
      number: "03",
    },
    {
      title: "Performance Tuning and Optimization",
      description:"Unio Labs helps clients optimize the performance of their systems and applications by identifying and resolving performance bottlenecks, improving system scalability, and enhancing system responsiveness.",
      number: "04",
    },
  ];
  const benefitCards = [
    {
      number: "01",
      text: "Enhanced Cybersecurity: Unio Labs provides a comprehensive security solution for clients' networks and systems, safeguarding against potential cyber-attacks and unauthorized access, thereby ensuring data privacy and integrity.",
    },
    {
      number: "02",
      text: "Improved System Performance: With Unio Labs' Security and Performance Engineering service, clients can optimize their system performance by identifying and resolving performance bottlenecks and inefficiencies, leading to better operational efficiency and cost savings.",
    },
    {
      number: "03",
      text: "Regulatory Compliance: Unio Labs' Security and Performance Engineering service helps clients adhere to industry standards and regulatory requirements, ensuring their systems are compliant and avoiding potential penalties and reputational damage.",
    },
    {
      number: "04",
      text: "Improved User Experience: With Unio Labs' Security and Performance Engineering service, clients can enhance the user experience of their systems, ensuring smooth and uninterrupted system access and usage, leading to increased user satisfaction and engagement.",
    }
  ];
  return (
    <FlexColGroup className={"service"}>
      <FirstSection
        title={
          "Ensuring Reliable and Secure Systems for Enhanced User Experience"
        }
        legendTitle={"Services / Security and Performance Engineering"}
        description={
          "Threat Modeling and Risk Assessment for Secure System Design"
        }
        image={FirstSectionImage}
      />
      <SecondSection card={card} />
      <ThirdSection
        infoCard={infoCard}
        title={"Unlock the Potential  with Our Comprehensive Services"}
        description={"Our offerings in Security and Performance Engineering"}
      />
      <ForthSection />
      <SixthSection benefitCards={benefitCards} />
      <LastSection />
    </FlexColGroup>
  );
}
export default SecurityAndPerformanceEngineering;
