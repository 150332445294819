import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/industry/FirstSection";
import SecondSection from "../../components/industry/SecondSection";
import "../../assets/css/service.css";
import ThirdSection from "../../components/industry/ThirdSection";
import ForthSection from "../../components/industry/ForthSection";
import FifthSection from "../../components/industry/FifthSection";
import FirstSectionImage from "../../assets/images/industry/HVAC/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function HVAC(){
    const card = {
        title : 'HVAC',
        description : "We provide smart building solutions for HVAC systems aimed at enhancing energy efficiency while reducing costs. Our predictive maintenance and real-time monitoring solutions enable us to detect and respond to HVAC issues quickly. We offer energy management and analytics solutions that can optimize HVAC operations, ensuring that heating and cooling systems run at peak efficiency. Our building automation solutions are designed to improve comfort and productivity in commercial buildings. We also provide indoor air quality monitoring solutions that help enhance occupant health and well being.",
        isLeft : false,
        image : require('../../assets/images/industry/HVAC/secondSection.png')
    }
    const infoCard = [{
        title: 'Energy Management Solutions',
        description: 'Unio Labs offers energy management solutions to help HVAC companies and building owners reduce their energy consumption and costs by using data analytics and machine learning algorithms  and optimize HVAC systems, improving energy efficiency and reducing carbon emissions.',
        number: '01'
    },{
        title: 'Predictive Maintenance Solutions',
        description: "Unio Labs provides predictive maintenance solutions to help HVAC companies and building owners proactively maintain their HVAC systems, reducing downtime and maintenance costs.",
        number: '02'
    },{
        title: 'Indoor Air Quality Monitoring',
        description: 'We use IoT sensors and data analytics to monitor air quality factors such as temperature, humidity, CO2 levels, and particulate matter, enabling companies to identify potential problems and take corrective action.',
        number: '03'
    },{
        title: 'Remote Monitoring and Diagnostics',
        description: 'Unio Labs offers remote monitoring and diagnostics solutions to help HVAC companies and building owners remotely monitor and diagnose HVAC system issues, improving response times and reducing costs.',
        number: '04'
    }]
    return(
        <FlexColGroup className={'industry'}>
            <FirstSection legend={'Industry / HVAC'} title={'Collaborate with Unio Labs for Efficient HVAC Solutions'} description={'Internet of Things (IoT) Solutions for Smart Building Management'} image={FirstSectionImage}/>
            <SecondSection card={card}/>
            <ThirdSection infoCard={infoCard} title={'Our Diverse Range of Offerings for HVAC'}
                description={'Our offerings in HVAC Industry'}
            />
            <ForthSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default HVAC;