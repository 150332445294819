import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";
function FirstSection(){
    return (
        <FlexColGroup className={'firstSection'} id={'first'}>
        <FlexColGroup className={"content"}>
          <FlexColGroup className={'main'}>
            <div className="margin"></div>
            <Label className={"title"}>
            About Us : <br/> Pioneering Innovation in Electronics .       
            </Label>
          </FlexColGroup>
      </FlexColGroup>
      </FlexColGroup>
      );
}
export default FirstSection;