import FlexColGroup from "../flex/FlexColGroup";
import Label from "../form/Label";

function Loading(){
    return(
        <FlexColGroup className={'splash-container'}>
              <Label className="title">UnioLabs</Label>
              <Label className="title subtitle">Think . Innovation</Label>
        </FlexColGroup>
    )
}
export default Loading;