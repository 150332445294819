import PhotoAlbum from "react-photo-album";
import FlexColGroup from "../flex/FlexColGroup";
    const photos = [
        {
            src : require("../../assets/images/gallery/image7.png"),
            width: 640,
            height: 495,
        },{
            src : require("../../assets/images/gallery/image10.png"),

            width: 640,
            height: 271
        },{
            src : require("../../assets/images/gallery/image5.png"),
            width: 595,
            height: 259
        } 
        ,{
            src : require("../../assets/images/gallery/image9.png"),
            width: 595,
            height: 512
        }
    ]
    export default function SecondSection() {
        return <FlexColGroup className={'ImagesList'}>
            <PhotoAlbum classname={''} layout="columns" columns="2" photos={photos} />;
        </FlexColGroup>
    }