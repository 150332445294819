import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/industry/FirstSection";
import SecondSection from "../../components/industry/SecondSection";
import "../../assets/css/service.css";
import ThirdSection from "../../components/industry/ThirdSection";
import ForthSection from "../../components/industry/ForthSection";
import FifthSection from "../../components/industry/FifthSection";
import FirstSectionImage from "../../assets/images/industry/HealthCare/firstSection.svg";
import LastSection from "../../components/discuss/LastSection";
function HealthCare(){
    const card = {
        title : 'Healthcare',
        description : "We offer data-driven solutions for healthcare providers and organizations to improve patient outcomes and reduce costs. Our services include predictive analytics for early disease detection, personalized treatment planning, and population health management. We also provide data integration and management solutions to streamline healthcare operations and improve data accessibility.",
        isLeft : false,
        image : require('../../assets/images/industry/HealthCare/secondSection.png')
    }
    const infoCard = [{
        title: 'Electronic Health Records (EHR) Optimization',
        description: 'Unio Labs offers EHR optimization solutions to help healthcare providers streamline their workflows, reduce documentation errors, and improve patient care.',
        number: '01'
    },{
        title: 'Eldercare Solution',
        description: "Our Eldercare Solution is designed to help caregivers and family members remotely monitor the health and well-being of elderly individuals. Using a combination of smart sensors and wearable technology, we provide real-time data on vital signs, activity levels, and sleep patterns.",
        number: '02'
    },{
        title: 'Healthcare analytics',
        description: 'In this solution, we use advanced analytics and machine learning algorithms to analyze patient data, identify trends, and predict outcomes, enabling healthcare providers to make more informed decisions and optimize their operations.',
        number: '03'
    },{
        title: 'Patient engagement',
        description: 'We offer patient portals, mobile apps, and other digital technologies to provide patients with convenient access to their health information, enable self-management of chronic conditions, and facilitate communication with healthcare providers.',
        number: '04'
    }]
    return(
        <FlexColGroup className={'industry'}>
            <FirstSection legend={'Industry / Healthcare'} title={'Collaborate with Unio Labs for Smart Healthcare Solutions'} description={'IoT-enabled Health Monitoring and Control for Improved Solutions'} image={FirstSectionImage}/>
            <SecondSection card={card}/>
            <ThirdSection infoCard={infoCard} title={'Our Diverse Range of Offerings for Healthcare'}
                description={'Our offerings in Healthcare Industry'}
            />
            <ForthSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default HealthCare;