import FlexColGroup from "../flex/FlexColGroup";
import { useEffect } from "react";
import Project from "./Project";

function SecondSection(){
    useEffect(() => {
        window.scrollTo(0,0);
      },[]);
    const projects = [
        {
            title : 'Control system for Fabric cutting machine',
            description : "Recently we worked on cutting-edge control panel for fabric cutting machines, designed to improve precision and productivity. With advanced automation capabilities and a user-friendly interface, it's the perfect solution for businesses looking to optimize their cutting process.",
            isLeft : false,
            number : "01",
            image : require('../../assets/images/portfolio/Project/project4.png')
        },{
            title : 'High speed data logger for High current and voltage.',
            description : "Introducing our high-speed data logger for recording high current and voltage levels in demanding environments. With its precision and reliability, compact design, and user-friendly interface, it's the perfect tool for the automotive, energy, and aerospace industries.",
            isLeft : true,
            number : "02",
            image : require('../../assets/images/portfolio/Project/project5.png')
        },{
            title : 'High precision Servo driver control interface',
            description : "With advanced control features and a user-friendly interface, our interface provides the perfect controller interface. Whether you're in the manufacturing, automation, or robotics industry, our servo driver control interface will help you achieve maximum efficiency and precision.",
            isLeft : false,
            number : "03",
            image : require('../../assets/images/portfolio/Project/project6.png')
        },{
            title : 'Air Quality monitoring and logging device',
            description : `Introducing our portable air quality monitoring and logging device, providing accurate and real-time data on air quality. With advanced sensors and a user-friendly interface, it's the perfect tool for tracking and analyzing air quality over time. Whether for indoor or outdoor use, it's valuable for promoting healthier and safer environments.` ,
            isLeft : true,
            number : "04",
            image : require('../../assets/images/portfolio/Project/project2.png')
        },{
            title : 'Control system and Remote diagnostics for an air conditioner.',
            description : "Our advanced control system and remote diagnostics for air conditioners, enabling easy performance monitoring and troubleshooting from a remote location. Perfect for residential and commercial use, it offers seamless integration and reduces the need for on-site maintenance.",
            isLeft : false,
            number : "05",
            image : require('../../assets/images/portfolio/Project/project1.png')
        },{
            title : 'Asset tracking',
            description : "In this project, our real-time tracking and monitoring solutions for assets and inventory enhance supply chain management and reduce losses. We offered data analytics tools that help us to assess asset utilization and predict maintenance needs, helped our clients to optimize asset performance and reduce downtime.",
            isLeft : true,
            number : "06",
            image : require('../../assets/images/portfolio/Project/project3.png')
        }
    ]
    const renderedProjects = projects.map((element)=>{
        return (
            <Project project={element}/>
        )
    })
    return(
        <FlexColGroup className={'secondSection'}>
            <FlexColGroup className={'main'}>
            <FlexColGroup className={'projects'}>
                {renderedProjects}
            </FlexColGroup>
            </FlexColGroup>
        </FlexColGroup>
    )
}
export default SecondSection;