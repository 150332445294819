import { useEffect } from "react";
import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";

function FirstSection() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <FlexColGroup className="firstSection">
      <FlexColGroup className={"content"}>
        <FlexColGroup className={'main'}>
          <div className="margin"></div>
          <Label className={"title"}>
          Unlocking Potential: Building Sustainability     
          </Label>
          <Label className={'description'}>
          Building a Better Future for All          
          </Label>
        </FlexColGroup>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default FirstSection;
