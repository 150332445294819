import FirstSection from "../components/career/FirstSection";
import FlexColGroup from "../components/flex/FlexColGroup";
import "../assets/css/career.css";
import SecondSection from "../components/career/SecondSection";
import FourthSection from "../components/career/FourthSection";
import ThirdSection from "../components/career/ThirdSection";
import LastSection from "../components/discuss/LastSection";
function Career(){
    return(
    <FlexColGroup className={'career'}>
        <FirstSection/>
        <SecondSection/>
        <ThirdSection/>
        <LastSection/>
    </FlexColGroup>
    );
}
export default Career;