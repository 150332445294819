import FlexRowGroup from "../flex/FlexRowGroup";
import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Label from "../form/Label";
import List from "./List";
function SecondSection() {
  return (
    <FlexRowGroup className={"secondSection"}>
      <Image alt={"Second Section Image"} className={"image"} />
      <FlexColGroup className={"content"}>
        <FlexColGroup className={"info"}>
          <Label className={"title"}>
          Let’s change the way of technology
          </Label>
          <Label className={"description"}>
          Our vision about Sustainability          
          </Label>
        </FlexColGroup>
        <FlexColGroup className={'info-list'}>
        <List/>

        </FlexColGroup>
      </FlexColGroup>
    </FlexRowGroup>
  );
}
export default SecondSection;
