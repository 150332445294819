import { useEffect } from "react";
import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";

function FirstSection({title,legendTitle,description,image}) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <FlexColGroup className="firstSection" style={{'background-image': `linear-gradient(90.15deg,#0c101c -10.1%,rgba(12, 16, 28, 0) 106.97%),url(${image})`}}>
      <FlexColGroup className={"content"}>
        <FlexColGroup className={'legend'}>
            <Label className={'legend-title'}>{legendTitle}</Label>
            <div className="margin"></div>
        </FlexColGroup>
        <FlexColGroup className={'header'}>
          <div className="margin"></div>
          <Label className={"title"}>
          {title}
          </Label>
          <Label className={'description'}>
          {description}         
          </Label>
        </FlexColGroup>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default FirstSection;
