import FlexColGroup from "../flex/FlexColGroup";
import Card from "./Card";

function SecondSection({card}){
    
    return(
        <FlexColGroup className={'secondSection'}>
            <FlexColGroup className={'cards'}>
               <Card card={card}/>
            </FlexColGroup>
        </FlexColGroup>
    )
}
export default SecondSection;