import FirstSection from "../components/contactus/FirstSection";
import FlexColGroup from "../components/flex/FlexColGroup";
import FlexRowGroup from "../components/flex/FlexRowGroup";
import "../assets/css/contact.css";
function ContactUs(){
    return(
        <FlexRowGroup className={'contactus'}>
        <FirstSection/>
        </FlexRowGroup>
    )
}
export default ContactUs;