import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Button from "../form/Button";
import Label from "../form/Label";
import Accordian from "./Accordian";
import { useNavigate } from "react-router-dom";
function ThirdSection() {
  const data = [
    {
      question: 'Q: What is the hiring process like at Unio Labs?',
      answer : 'A: The hiring process at Unio Labs typically involves a resume review, phone screen, and multiple rounds of interviews. Depending on the position, the hiring process may also involve technical assessments and a presentation.'
    },
    {
      question: 'Q: How long does the hiring process typically take at Unio Labs?',
      answer : 'A: The length of the hiring process can vary depending on the position and the number of applicants. However, we strive to make the process as efficient as possible while ensuring that we make the right hiring decisions.'
    },
    {
      question: 'Q: What is the preferred file format for submitting my resume?',
      answer : 'A: The preferred file format for submitting your resume at Unio Labs is usually a PDF or Word document. Other file formats may not be compatible with our Applicant Tracking System (ATS) or may not display properly, so it is best to stick to these two formats when submitting your resume.'
    },
    {
      question: 'Q: Does Unio Labs offer internship opportunities?',
      answer : 'A: Yes, we do offer internship opportunities for students who are interested in gaining experience in the tech industry. Our internship program provides hands-on experience, mentorship, and exposure to cutting-edge technology.'
    },{
      question : 'Q: Can I include links to my online portfolio or Linked In profile on my resume?',
      answer : 'A: Yes, you can include links to your online portfolio or LinkedIn profile on your resume, but it is not required. Including links can be a helpful way for hiring managers to learn more about your skills, experience, and accomplishments.'
    }
  ];

  const navigate = useNavigate();
  return (
    <FlexColGroup className={"thirdSection"}>
      <FlexColGroup className={"faq"}>
        <FlexColGroup className={"top"}>
          <FlexColGroup className={"header"}>
            <Label className={"title"}>FAQs</Label>
          </FlexColGroup>
          <FlexColGroup className={"content"}>
            <Label className={"description"}>
              Check this questions and answers that will provide you more
              clarity about general questions, application and hiring process,
              etc.
            </Label>
          </FlexColGroup>
        </FlexColGroup>
        <Accordian questions={data}/>
        <FlexColGroup className={"question"}>
            <Label className={'title'}>Still have a question ?</Label>
            <Button className={'hover-button'} onClick={
                () => {
                    navigate('/contact-us')
                }
            }>
                    <span>Let's Discuss</span>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_836_28083" style={{"mask-type":"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="27">
                    <rect y="0.5" width="26" height="26" />
                    </mask>
                    <g mask="url(#mask0_836_28083)">
                    <path d="M15.1668 20L13.6502 18.4292L17.496 14.5833H4.3335V12.4167H17.496L13.6502 8.57083L15.1668 7L21.6668 13.5L15.1668 20Z"/>
                    </g>
                    </svg>
                </Button> 
        </FlexColGroup>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default ThirdSection;
