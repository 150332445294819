import FlexColGroup from "../flex/FlexColGroup"
import FlexRowGroup from "../flex/FlexRowGroup"
import Shivakumar_Reddy from "../../assets/images/team/Shivakumar_Reddy.jpg"
import Shivakumar from "../../assets/images/team/Shivakumar.jpg"
import Fayaz from "../../assets/images/team/Fayaz.png"
import Bose from "../../assets/images/team/Bose.jpg"
import Pallavi from "../../assets/images/team/Pallavi.jpeg"
import Dayakar from "../../assets/images/team/Dayakar.jpeg"
import Mahendra from "../../assets/images/team/Mahendra.jpeg"
import Card from "./Card"

function SecondSection(){
    return(
        <FlexColGroup className={'secondSection'}>
            <FlexRowGroup className={'cards'}>
                <Card Name={'Mr. Mahendra Verma'} Designation={'Managing Director'} About={''} Profile={Mahendra}/>
                <Card Name={'Mr. Dayakar Konda'} Designation={'Project Manager & DevOps Engineer'} About={''} Profile={Dayakar}/>
                <Card Name={'Mr. Shivakumar Reddy'} Designation={'Project Architect'} About={''} Profile={Shivakumar_Reddy}/>
                <Card Name={'Mr. Sheikh Fayaz'} Designation={'Senior Embedded Programmer'} About={''} Profile={Fayaz} />
                <Card Name={'Mr. Shivakumar'} Designation={'PCB Designer'} About={''} Profile={Shivakumar}/>
                <Card Name={'Ms. Pallavi'} Designation={'Junior Embedded Engineer'} About={''} Profile={Pallavi}/>
                <Card Name={'Mr. Bose'} Designation={'Admin'} About={''} Profile={Bose}/>
            </FlexRowGroup>
        </FlexColGroup>
    )
}
export default SecondSection