import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";

function FirstSection() {
  return (
    <FlexColGroup className="firstSection">
      <FlexColGroup className={"content"}>
          <div className="margin"></div>
          <Label className={"title"}>
          "Unio Labs in Pictures :   A Visual Journey Through Our Innovations and Milestones"          </Label>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default FirstSection;
