import { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import FlexColGroup from "../flex/FlexColGroup";
    const photos = [
        {
            src : require("../../assets/images/gallery/image10.png"),
            height: 402,
            width: 393
        },
        {
            src : require("../../assets/images/gallery/image1.png"),
            height: 465.86,
            width: 393.12
        },{
            src : require("../../assets/images/gallery/image2.png"),
            height: 393.12,
            width: 262
        },{
            src : require("../../assets/images/gallery/image4.png"),
            height: 243.51,
            width: 393.12
        },
        {
            src : require("../../assets/images/gallery/image9.png"),
            height: 625.79,
            width: 393.12
        },{
            src : require("../../assets/images/gallery/image3.png"),
            height: 474.12,
            width: 393.12
        },{
            src : require("../../assets/images/gallery/image7.png"),
            height:393.12,
            width: 262
        },{
            src : require("../../assets/images/gallery/image8.png"),
            height:393.12,
            width: 262
        }
        ,{
            src : require("../../assets/images/gallery/image6.png"),
            height: 237.32,
            width: 393.12
        }
        ,{
            src : require("../../assets/images/gallery/image5.png"),
            height: 347.72,
            width: 393.12
        }
    ]
    export default function SecondSection() {
        const [photoAlbum,setPhotoAlbum] = useState();
        const handleResize = () =>{ 
            if(window.innerWidth < 1024){
                setPhotoAlbum(<PhotoAlbum classname={''} layout="columns" columns={2} photos={photos} />)
            }else{
                setPhotoAlbum(<PhotoAlbum classname={''} layout="columns" columns={3} photos={photos} />)
            }
        }
        useEffect(()=>{
          window.addEventListener("resize",handleResize)
        })
        useEffect(()=>{
            handleResize();
        },[])
        return <FlexColGroup className={'secondSection'}>
        {photoAlbum}
        </FlexColGroup>
    }