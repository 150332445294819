import FlexColGroup from "../flex/FlexColGroup";
import Label from "../form/Label";

function Card({card}){
    const renderedDescription = card.description.map((element)=>{
        return(
            <Label className={'description'}>
                {element}
            </Label>
        )
    })
    return(
        <FlexColGroup className={'card'}>
                <Label className={'title'}>
                    {card.title}
                </Label>
                <FlexColGroup className={'content'}>
                    {renderedDescription}
                </FlexColGroup>
                <div className="margin"></div>
        </FlexColGroup>
    )
}
export default Card;