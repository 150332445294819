import React, { useState, useEffect } from "react";
import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import CardShow from "./CardShow";
import Label from "../form/Label"; 

function ThirdSection() {
    const [selectIndex, setSelectIndex] = useState(0);
    const [expand, setExpand] = useState(false);
    const cards = [

        {
            title: "Embedded Systems",
            image : require('../../assets/images/home/thirdSection/electronic.png'),
            path  : "embedded-System"
        },{
            title: "Digital Twinning",
            image : require('../../assets/images/service/Digital Twinning/firstSection.png'),
            path  : "digital-twinning"
        },
        {
            title : "Integrated Engineering Solutions",
            image : require('../../assets/images/service/Integrated Engineering Solutions/firstSection.png'),
            path  : "integrated-engineering-solutions"
        },
        {
            title : "Internet of Things(IOT)",
            image : require('../../assets/images/service/Iot/firstSection.png'),
            path  : "internet-of-things"
        },
        {
            title : "Predictive and Preventive Maintenance",
            image : require('../../assets/images/service/Predictive and Preventive Maintenance/firstSection.png'),
            path  : "predictive-and-preventive-maintenance"
        },{
            title : "Artificial Intelligence(AI)",
            image : require('../../assets/images/home/thirdSection/ai.png'),
            path  : "artifical-intelligence"
            
        },{
            title: `Security and Performance Engineering`,
            image : require('../../assets/images/service/Security and Performance Engineering/firstSection.png'),
            path  : "security-and-performance-engineering"
        },
        {
            title : "Computer Vision Based Smart Inspection",
            image : require('../../assets/images/service/Computer Vision Based Smart Inspection/firstSection.png'),
            path  : "computer-vision-based-smart-inspection"
        }
    ];

    const cardsPerPage = 3;

    useEffect(() => {
        const interval = setInterval(() => {
            if (selectIndex < Math.ceil(cards.length / cardsPerPage) - 1) {
                setSelectIndex(selectIndex + 1);
            } else {
                setSelectIndex(0);
            }
        }, 4000); 
        return () => clearInterval(interval);
    }, [selectIndex, cards.length]);

    const renderedButtons = cards.map((element, index) => {
        if ((index) % 3 === 0) {
            return (
                <div key={index} className={`button ${(index / 3 === selectIndex) ? 'selected' : ''}`}></div>
            )
        }
    });

    return (
        <FlexColGroup className={'thirdSection'}>
            <FlexRowGroup className={"top"}>
                <Label className={'title'}>Services</Label>
            </FlexRowGroup>
            <FlexColGroup className={'services'}>
                <FlexRowGroup className={`content ${(expand ? 'expand' : '')}`}>
                    <CardShow cards={cards} index={selectIndex} className={`${(expand ? 'expand' : '')}`} />
                </FlexRowGroup>
                <FlexRowGroup className={`buttons ${(expand ? 'hide' : '')}`}>
                    {renderedButtons}
                </FlexRowGroup>
            </FlexColGroup>
        </FlexColGroup>
    );
}

export default ThirdSection;
