import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Image from "../form/Image";
import Label from "../form/Label";

function Card({card}){
    const {title,description,image,isLeft} = card;
    return(
        <FlexRowGroup className={'card'} style={(isLeft?{flexDirection : 'row-reverse'}:{})}>
            <Image src={image} className={'image'}/>
            <FlexColGroup className={'card-content'}>
                <Label className={'card-title'}>{title}</Label>
                <Label className={'card-info'}>{description}</Label>
            </FlexColGroup>
        </FlexRowGroup>
    )
}
export default Card;