import FirstSection from "../components/culture/FirstSection";
import SecondSection from "../components/culture/SecondSection";

import FlexColGroup from "../components/flex/FlexColGroup";
import "../assets/css/culture.css";
import ThirdSection from "../components/team/ThirdSection";
import LastSection from "../components/discuss/LastSection";

function Culture(){
    return(
        <FlexColGroup className={'culture'}>
            <FirstSection/>
            <SecondSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default Culture;