import FlexRowGroup from "../flex/FlexRowGroup";
import Image from "../form/Image";
import Logo from "../../assets/images/navbar/logo.svg";
import Search from "../../assets/images/navbar/search.svg";
import Label from "../../components/form/Label";
import { NavLink, useNavigate } from "react-router-dom";
import FlexColGroup from "../flex/FlexColGroup";
import Arrow from "../../assets/images/navbar/arrow.svg"
import DropArrow from "../../assets/images/navbar/arrowBack.svg"
import SelectDropArrow from "../../assets/images/navbar/selectArrowBack.svg"
import Menu from "../../assets/images/navbar/menu.svg"
import { useEffect, useState } from "react";

function NavbarContainer() {
  const navigate = useNavigate();
  const [selectIndex,setSelectIndex] = useState(null);
  const [show,setShow] = useState(false);
  const [renderedNavbarElements,setRenderedNavbarElement] = useState(null);
  const [isSearchExpand,setIsSearchExpand] = useState(false);
  const elements = [
    {
      name: "Industry",
      path : 'industry',
      children : [
        {
          name: "Digital Agriculture",
          path : 'digital-Agriculture',
        },{
          name: "Manufacturing",
          path : 'manufacturing',
        },{
          name: "Healthcare",
          path : 'healthcare',
        },{
          name: "HVAC",
          path : 'hvac',
        },{
          name: "Energy and Utilities",
          path : 'energy-Utilites',
        },{
          name : 'Asset tracking',
          path : 'asset-tracking'
        },{
          name : 'Logistics',
          path : 'logistics'
        }
      ],
    },
    {
      name: "Services",
      path : 'services',
      children : [
        {
          name: "Embedded Systems",
          path : 'embedded-System',
         
        },{
          name : 'Digital Twinning',
          path : 'digital-twinning'
          
        },{
          name: "Integrated Engineering Solutions",
          path : 'integrated-engineering-solutions',
        },{
          name: "Internet of Things",
          path : 'internet-of-things',
        },{
          name : 'Predictive and Preventive Maintenance',
          path : 'predictive-and-preventive-maintenance'
        },
        {
          name: "Artificial Intelligence",
          path : 'artifical-intelligence',
        },
        {
          name: "Security and Performance Engineering",
          path : 'security-and-performance-engineering',
        },{
          name : 'Computer Vision Based Smart Inspection',
          path : 'computer-vision-based-smart-inspection'
        }
      ],
    },
    {
      name: "Innovation",
      path : 'innovation',
      children : null,
    },
    {
      name: "About Us",
      path : 'about',
      children : null,
    },
    {
      name : "Solutions",
      path : 'solutions.uniolabs.com',
      children : null,
    },
    {
      name: "Portfolio",
      path : 'portfolio',
      children : [
        {
          name: "Projects",
          path : '',
        },{
          name: "Gallery",
          path : 'gallery',
        }
      ]
    },
    {
      name: "People",
      path : 'people',
      children : [
        {
          name : 'Team',
          path : 'team'
        },{
          name : 'Culture',
          path : 'culture'
        }
      ]
    }, {
      name: "Contact Us",
      path : 'contact-us'
    },
    {
      name: "Career",
      path : 'career'
    }
  ];
  const buttons = [
    {
      name: "Contact Us",
      path : 'contact-us'
    },
    {
      name: "Career",
      path : 'career'
    },
  ];
  const onHandleClick = (e,index) =>{
    e.preventDefault();
    if(index ===  selectIndex)
    {
      setSelectIndex(null);
    }else{
      setSelectIndex(index)
    }
      
  }
  useEffect(()=>{
    if(selectIndex!=null ){
      if(elements[selectIndex-1].children){
        console.log(elements[selectIndex-1].children)
        const renderdNavbarSubElements = elements[selectIndex-1].children.map((element)=>{
          if (element.name === 'Solutions') {
            return (         
              <a href="https://www.google.com" >

              </a>
      
            );
          }
          
          const target = element.isExternal ? '_blank' : '_self';
          
          return(
            <FlexRowGroup className={'navbar-element'}>
              <NavLink to={elements[selectIndex-1].path+"/"+element.path} className={"navbar-element-list"} onClick={()=>{
                setSelectIndex(null)
                setShow(false)

              }}
              target = {target}
              >
                {element.name}
              </NavLink>
              <Image className={'icons'} src={Arrow}/>
          </FlexRowGroup>
          );
        })
        setRenderedNavbarElement(renderdNavbarSubElements);
      }else{
        navigate(elements[selectIndex-1].path)
        setShow(false)
        setSelectIndex(null);
        setRenderedNavbarElement(null);
      }
     
    }
  },[selectIndex])
  const renderedElement = elements.map((element, index) => {
    // Check if the element is "Team" inside "People"
    const isTeamButton = element.name === "Team" && element.path === "people";
    // Check if the element is "Innovation" or "Solutions"
    const isDisabled = element.name === "Innovation" || element.name === "Solutions";
  
    return (
      <FlexColGroup key={index} className={`navbar-elements-drop ${isDisabled ? 'disabled' : ''}`}>
        {isDisabled ? (
          <div className="navbar-element-list disabled">
            {element.name}
            {element.children ? (
              <Image src={DropArrow} className={`drop-icons ${(index === selectIndex - 1) ? 'selected' : ''}`} />
            ) : ''}
          </div>
        ) : (
          <NavLink
            to={element.path}
            className={`navbar-element-list ${isTeamButton ? 'people-button' : ''}`} // Apply the "people-button" class
            onClick={(e) => {
              if (isTeamButton) {
                e.preventDefault(); // Prevent default behavior for "Team" button
              } else {
                onHandleClick(e, index + 1);
              }
            }}
            disabled={isTeamButton} // Disable the link if it's the "Team" button
          >
            {element.name}
            {element.children ? (
              <Image src={DropArrow} className={`drop-icons ${(index === selectIndex - 1) ? 'selected' : ''}`} />
            ) : ''}
          </NavLink>
        )}
        {(index === selectIndex - 1) && (
          <FlexColGroup className={'navbar-sublist-elements'}>
            {renderedNavbarElements}
          </FlexColGroup>
        )}
      </FlexColGroup>
    );
  });
  
  
  const renderedButtons = buttons.map((element, index) => {
    return (
      <NavLink
        key={index}
        to={element.path}
        className="navbar-element-list"
        onClick={(e) => {
          setSelectIndex(null);
        }}
      >
        {element.name}
      </NavLink>

      
    );
  });
  return (
  <FlexColGroup className={'nav'}>
  <FlexRowGroup className={"navbar-container"}>
      <FlexRowGroup className={"navbar-element"}>
          <Image src={Logo} className={'logo'} onClick={()=>{
            setSelectIndex(null);
            navigate('/')
          }}></Image>      
        <FlexRowGroup className={`navbar-header-elements ${(show)?'show':''}`}>
          {renderedElement}
        </FlexRowGroup>
      </FlexRowGroup>
      <FlexRowGroup className={"navbar-element-buttons"} >
        <FlexRowGroup className={"navbar-header-elements"}>
          {renderedButtons}
        </FlexRowGroup>
        <FlexRowGroup className={'search-button'} onClick={()=>{
          <input type="text" placeholder="Search"/>
        }}>
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.29297 12.2925C5.29297 8.42649 8.42698 5.29248 12.293 5.29248C16.159 5.29248 19.293 8.42649 19.293 12.2925C19.293 14.094 18.6124 15.7366 17.4944 16.9771L16.9776 17.4939C15.737 18.6119 14.0945 19.2925 12.293 19.2925C8.42698 19.2925 5.29297 16.1585 5.29297 12.2925ZM17.9104 19.3246C16.3708 20.5561 14.4179 21.2925 12.293 21.2925C7.32241 21.2925 3.29297 17.263 3.29297 12.2925C3.29297 7.32192 7.32241 3.29248 12.293 3.29248C17.2635 3.29248 21.293 7.32192 21.293 12.2925C21.293 14.4176 20.5564 16.3707 19.3247 17.9104L23 21.5858L23.7071 22.2929L22.2929 23.7071L21.5858 23L17.9104 19.3246Z" fill="#F8F8F8"/>
</svg>
         
        </FlexRowGroup>
        <Image className={'menu-icon'} src={Menu} style={{'cursor':'pointer'}} onClick={()=>{
          setShow(!show)
        }}/>
      </FlexRowGroup>
    </FlexRowGroup>
    <FlexColGroup className={`navbar-element-sublist ${(selectIndex)?'expands':''}`}>
       <FlexColGroup className={'navbar-element-sublist-container'}>
       {renderedNavbarElements}
       </FlexColGroup>
    </FlexColGroup>
  </FlexColGroup>
  );
}
export default NavbarContainer;
