import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/industry/FirstSection";
import SecondSection from "../../components/industry/SecondSection";
import "../../assets/css/service.css";
import ThirdSection from "../../components/industry/ThirdSection";
import ForthSection from "../../components/industry/ForthSection";
import FifthSection from "../../components/industry/FifthSection";
import FirstSectionImage from "../../assets/images/industry/HVAC/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function AssetTracking() 
{
  const card = {
    title: "Mining",
    description:
      "At Unio Labs, we provide comprehensive mining solutions aimed at optimizing resource extraction and management. Our data analytics tools allow us to assess ore quality and equipment utilization, helping us to optimize mining operations and reduce costs. Our predictive maintenance solutions enable us to prevent equipment failures and reduce downtime. We offer real-time monitoring solutions for equipment and worker safety, helping to improve mine safety and reduce risks. We also offer asset tracking solutions that provide real-time tracking and monitoring for mining equipment and inventory, which enhances supply chain management and reduces losses.",
    isLeft: false,
    image: require("../../assets/images/industry/HVAC/secondSection.png"),
  };
  const infoCard = [
    {
      title: "Digital Twinning",
      description:
        " Our digital twinning solutions can create virtual replicas of mining operations, allowing for scenario testing and optimization. This can help mining companies make more informed decisions and improve overall performance.",
      number: "01",
    },
    {
      title: "Equipment Maintenance and Reliability",
      description:
        "We provides equipment maintenance and reliability solutions to help mining companies maximize the uptime of their equipment and reduce maintenance costs.",
      number: "02",
    },
    {
      title: "Safety Monitoring and Management",
      description:
        "We provides safety monitoring and management solutions to help mining companies ensure the safety of their workers and reduce accidents.",
      number: "03",
    },
    {
      title: "Mineral Processing Optimization",
      description:
        "We offers mineral processing optimization solutions to help mining companies improve the efficiency of their mineral processing operations and reduce costs.",
      number: "04",
    },
  ];
  return (
    <FlexColGroup className={"industry"}>
      <FirstSection
        legend={"Industry / Asset tracking"}
        title={"Collaborate with Unio Labs for IoT-enabled Mining Solutions."}
        description={
          "IoT-enabled Mining Solutions for Improved Safety and Efficiency"
        }
        image={FirstSectionImage}
      />
      <SecondSection card={card} />
      <ThirdSection
        infoCard={infoCard}
        title={"Our Diverse Range of Offerings for Mining"}
        description={"Our offerings in Mining Industry"}
      />
      <ForthSection />
      <LastSection />
    </FlexColGroup>
  );
}

export default AssetTracking;

