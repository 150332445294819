import FlexColGroup from "../components/flex/FlexColGroup";
import "../assets/css/innovation.css";
import FirstSection from "../components/innovation/FirstSection";
import SecondSection from "../components/innovation/SecondSection";
import ThirdSection from "../components/innovation/ThirdSection";
import LastSection from "../components/discuss/LastSection";
function Innovation(){
    return(
        <FlexColGroup className={'innovation'}>
            <FirstSection/>
            <SecondSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default Innovation;