import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Image from "../form/Image";
import Label from "../form/Label";

function Resource({resource}){
  const {title,description,image,isLeft}  = resource;
    return(
        <FlexRowGroup className={"main"} style = {(isLeft)?{flexDirection : 'row-reverse'}:{}}>
        <Image src={image} alt={"Second Section Image"} className={"image"} />
        <FlexColGroup className={"content"}>
        <div className="margin"></div>
          <FlexColGroup className={"info"}>
            <Label className={"title"}>
            {title}
            </Label>
            <Label className={"description"}>
           {description}            </Label>
          </FlexColGroup>
        </FlexColGroup>
      </FlexRowGroup>
    )
}
export default Resource;