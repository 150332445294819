import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";

function FirstSection() {
  return (
    <FlexColGroup className="firstSection">
      <FlexColGroup className={"content"}>
        <FlexColGroup className={'top'}>
          <div className="margin"></div>
          <Label className={"title"}>
          Meet Our Team : The Driving Force Behind Unio Labs Success          </Label>
        </FlexColGroup>
        <Label className={"description"}>
        At Unio Labs, our team is the driving force behind our success. We are a diverse group of talented individuals with unique strengths and backgrounds, united by our passion for innovation and excellence. We believe that collaboration and support are key to achieving our goals, and we foster a culture of teamwork and growth that encourages everyone to contribute their best ideas.
        </Label>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default FirstSection;
