import { useEffect, useRef } from "react";
import FlexColGroup from "../../flex/FlexColGroup";
import Image from "../../form/Image";
// import useHorizontalScroll from "./useHorizontalScroll";
function Journey({card}){
    // const journey = useHorizontalScroll();
    return(
        <FlexColGroup  className={'journey'}>
            <Image src={card.image}/>
        </FlexColGroup>
    )
}
export default Journey;