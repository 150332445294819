import FlexColGroup from "../components/flex/FlexColGroup";
import Label from "../components/form/Label";
import "../assets/css/termsandcondition.css";
import { useEffect } from "react";
function TermsAndCondition() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <FlexColGroup className={"termsandcondition"}>
      <FlexColGroup className={"header"}>
        <Label className={"title"}>Terms and Conditions</Label>
        <Label className={"description"}>
         These terms and conditions outline the rules and regulations for the
          use of Unio Labs's Website, located at <a href={'http://www.uniolabs.com/'}>http://www.uniolabs.com/</a><br/><br/>
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements: "Client",
          "You" and "Your" refers to you, the person log on this website and
          compliant to the Company’s terms and conditions. "The Company",
          "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
          "Parties", or "Us", refers to both the Client and ourselves. All terms
          refer to the offer, acceptance and consideration of payment necessary
          to undertake the process of our assistance to the Client in the most
          appropriate manner for the express purpose of meeting the Client’s
          needs in respect of provision of the Company’s stated services, in
          accordance with and subject to, prevailing law of Netherlands. Any use
          of the above terminology or other words in the singular, plural,
          capitalization and/or he/she or they, are taken as interchangeable and
          therefore as referring to same.
        </Label>
        <div className="margin"></div>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default TermsAndCondition;
