import FlexColGroup from "../flex/FlexColGroup";
import Card from "./Card";

function SecondSection(){
    const card = [{
        title : "Empowering Aging with Alexa's Tailored Elderly Care Innovation",
        description : "AI services are an innovative and exciting way to leverage the power of artificial intelligence to improve business operations and enhance customer experience. With the increasing demand for AI-driven solutions in almost every industry, businesses are now recognizing the potential of AI services to streamline their processes, automate tasks, and gain valuable insights into their data.",
        isLeft : false,
        image : require('../../assets/images/innovation/secondSection/ECE website image 3.jpg')
    },{
        title : 'AI-Enabled Air Conditioner : Voice-Controlled with Remote Diagnosis',
        description : "AI services are an innovative and exciting way to leverage the power of artificial intelligence to improve business operations and enhance customer experience. With the increasing demand for AI-driven solutions in almost every industry, businesses are now recognizing the potential of AI services to streamline their processes, automate tasks, and gain valuable insights into their data.",
        isLeft : true,
        image : require('../../assets/images/innovation/secondSection/ECE website image 4.jpg')
    },{
        title : 'Real-Time Data Capture for Sports Performance Analysis',
        description : "AI services are an innovative and exciting way to leverage the power of artificial intelligence to improve business operations and enhance customer experience. With the increasing demand for AI-driven solutions in almost every industry, businesses are now recognizing the potential of AI services to streamline their processes, automate tasks, and gain valuable insights into their data.",
        isLeft : false,
        image : require('../../assets/images/innovation/secondSection/ECE website image 2.jpg')
    },{
        title : 'Advanced Wireless Andon System : Pioneering the Future of Manufacturing',
        description : "AI services are an innovative and exciting way to leverage the power of artificial intelligence to improve business operations and enhance customer experience. With the increasing demand for AI-driven solutions in almost every industry, businesses are now recognizing the potential of AI services to streamline their processes, automate tasks, and gain valuable insights into their data.",
        isLeft : true,
        image : require('../../assets/images/innovation/secondSection/IoT  website image 5.jpg')
    },{
        title : 'Revolutionizing Diabetes Care : Non-Invasive Glucose Monitoring Solution',
        description : "AI services are an innovative and exciting way to leverage the power of artificial intelligence to improve business operations and enhance customer experience. With the increasing demand for AI-driven solutions in almost every industry, businesses are now recognizing the potential of AI services to streamline their processes, automate tasks, and gain valuable insights into their data.",
        isLeft : false,
        image : require('../../assets/images/innovation/secondSection/ECE website image 2.jpg')
    },{
        title : 'Smart Air-Quality Monitoring System with AI Analysis',
        description : "AI services are an innovative and exciting way to leverage the power of artificial intelligence to improve business operations and enhance customer experience. With the increasing demand for AI-driven solutions in almost every industry, businesses are now recognizing the potential of AI services to streamline their processes, automate tasks, and gain valuable insights into their data.",
        isLeft : true,
        image : require('../../assets/images/innovation/secondSection/IoT  website image 5.jpg')
    }]
    const renderedCards = card.map((element)=>{
        return (
            <Card card = {element}/>
        )
    })
    return(
        <FlexColGroup className={'secondSection'}>
            <FlexColGroup className={'cards'}>
                {renderedCards}
            </FlexColGroup>
        </FlexColGroup>
    )
}
export default SecondSection;