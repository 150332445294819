import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";

function SixthSection({benefitCards}){
    const renderedElement = benefitCards.map((element)=>{
        return(
            <FlexRowGroup className={'card'}>
                    <Label className={'card-number'}>
                        {element.number}
                    </Label>
                    <Label className={'card-text'}>
                    {element.text}
                    </Label>
            </FlexRowGroup>
        )
    })
    return(
        <FlexColGroup className={'sixthSection'}>
            <Label className={'title'}>Benefits</Label>
            <FlexColGroup className={'container'}>
            <FlexColGroup className={'content'}>
                    {renderedElement}
            </FlexColGroup>
            </FlexColGroup>
        </FlexColGroup>
    )
}
export default SixthSection;