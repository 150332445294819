import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Button from "../form/Button";
import Image from "../form/Image";
import Label from "../form/Label";

function Project({ project }) {
  const { number, title, image, description, isLeft } = project;
  return (
    <FlexRowGroup
      className={"project"}
      style={isLeft ? { flexDirection: "row-reverse" } : {}}
    >
      <Label className={`project-number ${isLeft ? 'right' : 'left'}`}>{number}</Label>
      <FlexRowGroup
        className={"project-content"}
        style={isLeft ? { flexDirection: "row-reverse" } : {}}
      >
        <Image src={image} className={"image"}></Image>
        <FlexColGroup className={"project-info"}>
          <FlexColGroup className={"project-main"}>
            <div className="margin"></div>
            <Label className={"project-title"}>{title}</Label>
            <Label className={"project-description"}>{description}</Label>
          </FlexColGroup>
          <FlexColGroup className={"content"}>
            <Button className={"see-more-button"} role={Button}>
              See more
            </Button>
          </FlexColGroup>
        </FlexColGroup>
      </FlexRowGroup>
    </FlexRowGroup>
  );
}
export default Project;
