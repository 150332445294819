import FlexRowGroup from "../../flex/FlexRowGroup";
import Image from "../../form/Image";
import Card from "./Card";
import ValueImage from "../../../assets/images/aboutus/ThirdSection/value.jpg"
function Value(){
    const cards = [
        {
            image : require('../../../assets/images/aboutus/ThirdSection/Values/innovation.png'),
            title : 'Innovation',
            description : `The company is always exploring new technologies and finding ways to bring cutting-edge solutions to its customers.`
        },{
            image : require('../../../assets/images/aboutus/ThirdSection/Values/collaboration.png'),
            title : 'Collaboration',
            description : `we always values teamwork and encourages its employees to work together to find creative solutions for our customers.`
        },{
            image : require('../../../assets/images/aboutus/ThirdSection/Values/quality.png'),
            title : 'Quality',
            description : `we committed to providing high-quality services and products that meet or exceed their expectations.`
        },{
            image : require('../../../assets/images/aboutus/ThirdSection/Values/customer.png'),
            title : 'Customer Satisfaction',
            description : `we places a high priority on customer satisfaction and strives to understand the unique needs of each business.`
        },{
            image : require('../../../assets/images/aboutus/ThirdSection/Values/integrity.png'),
            title : 'Integrity',
            description : `The company operates with honesty and transparency, and its employees are expected to conduct themselves with the highest ethical standards.`
        },{
            image : require('../../../assets/images/aboutus/ThirdSection/Values/learning.png'),
            title : 'Learning',
            description : `Unio Labs encourages its employees to never stop learning and growing, and provides opportunities for professional development.`
        }
    ]
    const renderedCards = cards.map((element)=>{
        return <Card card={element}/>
    });
    return(
        <FlexRowGroup className={'value'}>
            <Image src={ValueImage} className={'left-image'}/>
            <FlexRowGroup className={'cards'}>
                {renderedCards}
            </FlexRowGroup>
        </FlexRowGroup>
    )
}
export default Value;