import FlexRowGroup from "../flex/FlexRowGroup";
import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Label from "../form/Label";
function SecondSection() {
  return (
    <FlexRowGroup className={"secondSection"}>
      <FlexColGroup className={"content"}>
        <FlexColGroup className={"info"}>
          <Label className={"title"}>
          UNIO LABS
          </Label>
          <Label className={"description"}>
          is a cutting-edge electronics development company based in Hyderabad and business center in UK that specializes in developing and implementing the latest technologies in hardware and software. We provide innovative and simple embedded solutions to complex problems in industries and workshops. Our Smart Automation System is designed to make life easier and more efficient for homes, offices, and institutions. At Unio Labs, we are dedicated to revolutionizing the world of electronics and bridging the gap between hardware and software. Join us on this exciting journey towards a more connected and innovative future.
          </Label>
        </FlexColGroup>
      
      </FlexColGroup>

      <Image alt={"Second Section Image"} className={"image"} />
    </FlexRowGroup>
  );
}
export default SecondSection;
