function List(){
    const list = "We committed to developing and implementing technologies that are sustainable and environmentally-friendly, such as smart automation systems and energy-efficient solutions. We strive to minimize our carbon footprint by promoting the use of renewable energy sources and reducing waste We source our materials and supplies from sustainable suppliers who share our values towards environmental sustainability. We constantly review and improve our sustainability practices to ensure we are doing all we can to minimize our environmental impact."
    const brokenList = list.split('.');
    const renderedList = brokenList.map((element)=>{
        return (
            (element === "")? '' : <li>{element}</li>
        )
    })
    return(
        <ul className="list">
            {renderedList}
        </ul>
    )
}
export default List;