import FlexRowGroup from "../flex/FlexRowGroup";
import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Label from "../form/Label";
import { useNavigate } from "react-router-dom";
import Button from "../form/Button";
function FifthSection() {
  const navigate = useNavigate();
  return (
    <FlexRowGroup className={"fifthSection"}>
      <FlexColGroup className={"content"}>
        <FlexColGroup className={"info"}>
          <Label className={"title"}>
            About <span>UN</span>
            <span>IO</span> LABS
          </Label>
          <Label className={"description"}>
          Unio Labs is a cutting-edge electronics development company based in Hyderabad with a business center in the UK. We specialize in developing and implementing the latest technologies in hardware and software. We are dedicated to revolutionizing the world of electronics and bridging the gap between hardware and software. Join us on this exciting journey towards a more connected and innovative future.
          </Label>
        </FlexColGroup>
        <Button className={'hover-button'} onClick={()=>{
          navigate('/about')
        }}>
        <span>Know More</span>
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_836_28083" style={{"mask-type":"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="27">
          <rect y="0.5" width="26" height="26" />
          </mask>
          <g mask="url(#mask0_836_28083)">
          <path d="M15.1668 20L13.6502 18.4292L17.496 14.5833H4.3335V12.4167H17.496L13.6502 8.57083L15.1668 7L21.6668 13.5L15.1668 20Z"/>
          </g>
          </svg>
        </Button>
      </FlexColGroup>

      <Image alt={"Second Section Image"} className={"image"} />
    </FlexRowGroup>
  );
}
export default FifthSection;
