import { useNavigate } from "react-router-dom";
import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Button from "../form/Button";
import Label from "../form/Label";
import Project from "./Project";
import Image from "../form/Image";
import Logo from "../../assets/images/navbar/logo.svg";
function FourthSection() {
    const projects = [
        {
            title: 'Control system for Fabric cutting machine',
            description: "Recently we worked on cutting-edge control panel for fabric cutting machines, designed to improve precision and productivity. With advanced automation capabilities and a user-friendly interface, it's the perfect solution for businesses looking to optimize their cutting process.",
            isLeft: false,
            image: require('../../assets/images/portfolio/Project/project4.png')
        }, {
            title: 'High speed data logger for High current and voltage.',
            description: "Introducing our high-speed data logger for recording high current and voltage levels in demanding environments. With its precision and reliability, compact design, and user-friendly interface, it's the perfect tool for the automotive, energy, and aerospace industries.",
            isLeft: true,
            image: require('../../assets/images/portfolio/Project/project5.png')
        }, {
            title: 'High precision Servo driver control interface',
            description: "With advanced control features and a user-friendly interface, our interface provides the perfect controller interface. Whether you're in the manufacturing, automation, or robotics industry, our servo driver control interface will help you achieve maximum efficiency and precision.",
            isLeft: false,
            image: require('../../assets/images/portfolio/Project/project6.png')
        }, {
            title: 'Air Quality monitoring and logging device',
            description: `Introducing our portable air quality monitoring and logging device, providing accurate and real-time data on air quality. With advanced sensors and a user-friendly interface, it's the perfect tool for tracking and analyzing air quality over time. Whether for indoor or outdoor use, it's valuable for promoting healthier and safer environments.`,
            isLeft: true,
            image: require('../../assets/images/portfolio/Project/project2.png')
        }
    ]
    const navigate = useNavigate();
    const renderedProjects = projects.map((element) => {
        return (
            <Project project={element} />
        )
    })
    
    return (
        <FlexColGroup className={'fourthSection'}>
            <FlexRowGroup className={'top'}>
                <div className="margin"></div>
                <FlexColGroup className={'content'}>
                    <Label className={'title'}>Leading the Way : Our Latest <span>Development</span> </Label>
                    <Label className={'description'}>Refers to the cutting-edge innovation that we have recently achieved or implemented. It is the result of our dedication to pushing the boundaries of what is possible and constantly striving to improve and evolve.</Label>
                </FlexColGroup>
            </FlexRowGroup>
            <FlexColGroup className={'main'}>
                <FlexColGroup className={'projects'}>
                    {renderedProjects}
                </FlexColGroup>
            </FlexColGroup>
            <div className="margin"></div>
            <FlexColGroup className={'view-all'} >
              
                <div  className="button-space">
                <Button className={'view-all-button'} role={'Button'} onClick={
                () => {
                    navigate('/portfolio')
                }
            }>
                    <span>View All</span>
                </Button>
                </div>
                {/* <div className="all">
                    <div className="lefter">
                        <div className="text" style={{paddingLeft:"55px"}}>VISION</div>
                    </div>
                    <div className="left">
                        <div className="text" >OPEN COMMUNICATION</div>
                    </div>
                    <div className="center">
                        <div className="explainer"><span><Image src={Logo} className={'logo'} /></span></div>
                        <div className="text" style={{paddingLeft:"40px"}}>ENGAGEMENT</div>
                    </div>
                    <div className="right">
                        <div className="text" style={{paddingLeft:"40px"}}>DEVELOPMENT</div>
                    </div>
                    <div className="righter">
                        <div className="text" style={{paddingLeft:"55px"}} >REWARDS</div>
                    </div>
                </div> */}
            </FlexColGroup>
        </FlexColGroup>
    )
}
export default FourthSection;
