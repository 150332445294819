import FlexColGroup from "../../flex/FlexColGroup";
import FlexRowGroup from "../../flex/FlexRowGroup";
import Image from "../../form/Image";
import Label from "../../form/Label";
function Vision({card}){
    return(
        <FlexRowGroup className={'vision'}>
            <Image src={card.image} className={'image'}/>
            <FlexColGroup className={'content'}>
                <Label className={'title'}>
                    {card.title}
                </Label>
                <Label className={'description'}>
                    {card.description}
                </Label>
            </FlexColGroup>
        </FlexRowGroup>
    )
}
export default Vision;