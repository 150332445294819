import FlexColGroup from "../components/flex/FlexColGroup";
import FirstSection from "../components/team/FirstSection";
import "../assets/css/team.css";
import SecondSection from "../components/team/SecondSection";
import ThirdSection from "../components/team/ThirdSection";
import LastSection from "../components/discuss/LastSection";
function Team(){
    return (
        <FlexColGroup className={'team'}>
            <FirstSection/>
            <SecondSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default Team;