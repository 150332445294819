import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Image from "../form/Image";
import Label from "../form/Label";
import Back from "../../assets/images/career/secondSection-back.svg";
import LocationIcon from "../../assets/images/career/secondSection-Location.svg";
import WorkIcon from "../../assets/images/career/secondSection-Work.svg";
import CurrencyRuppes from "../../assets/images/career/try.png";
import { useState } from "react";
import Button from "../form/Button";
import Close from "../../assets/images/career/close.svg";

function JobCard({jobTitle ,jobDescription ,location ,experience ,JobType,DepartmentName}){
    const [isExpand,setIsExpand] = useState(false);
    const jobPoints = jobDescription ? jobDescription.split('\n').filter(point => point.trim() !=='') : [];
    return (
        <FlexColGroup className={`card ${isExpand?'expand':''}`}>
        <FlexColGroup className={'header'}>
            <Label className={'title'}>
            {jobTitle}
            </Label>
            <Label className={'description'}>
            {DepartmentName}
            </Label>
        </FlexColGroup>
        <FlexRowGroup className={'info'}>
            <FlexRowGroup className={'details'}>
                <FlexRowGroup className={'tags'} >
                    <Image className={'tagicon'} src={LocationIcon}/>
                    <Label className={'tagname'}>{location}</Label>
                </FlexRowGroup>
                <FlexRowGroup className={'tags'}>
                    <Image className={'tagicon'} src={WorkIcon}/>
                    <Label className={'tagname'}>{JobType}</Label>
                </FlexRowGroup>
                <FlexRowGroup className={'tags'}>
                    <Image className={'tagicon1'} src={CurrencyRuppes}/>
                    <Label className={'tagname1'}>{experience}</Label>
                </FlexRowGroup>
            </FlexRowGroup>
            <FlexRowGroup className={'buttons'}>
                <Button className={`button-one ${isExpand?'expand':''}`} onClick={
                    ()=>{
                        setIsExpand(!isExpand)
                    }
                }>
                        See Details
                    <Image className={`${isExpand?'expand':''}`} src={Back}></Image>
                </Button>
                <FlexRowGroup className={'button-two'}>
                    <Label className={'text'}>Apply Now</Label>
                </FlexRowGroup>
            </FlexRowGroup>
        </FlexRowGroup>
        <FlexRowGroup className={'job-details'}>
            <FlexColGroup className={'details-content'}>
                <FlexColGroup className={'main-details'}>
                    <FlexColGroup className={'job-description'}>
                        <Label className={'title'}>Job Description -</Label>
                        <ul className={'job-info'}>
                        {jobPoints.map((point,index) => (
                            <li key={index}>{point}</li>
                        ))}
                        </ul>
                    </FlexColGroup>
                </FlexColGroup>
                <FlexColGroup className={'job-skills'}>
                    <Label className={'title'}>
                    Skills Required
                    </Label>
                    <FlexRowGroup className={'list-skills'}>
                       <FlexColGroup className={'skill'}>
                            Digital Market
                       </FlexColGroup>
                       <FlexColGroup className={'skill'}>
                            Digital Market
                       </FlexColGroup>
                       <FlexColGroup className={'skill'}>
                            Digital Market
                       </FlexColGroup>
                       <FlexColGroup className={'skill'}>
                            Digital Market
                       </FlexColGroup>
                       <FlexColGroup className={'skill'}>
                            Digital Market
                       </FlexColGroup>

                    </FlexRowGroup>
                </FlexColGroup>
            </FlexColGroup>
            <Image style={{'cursor': 'pointer'}} src={Close} onClick={()=>{
                        setIsExpand(false)
                    }}/>
        </FlexRowGroup>
    </FlexColGroup>
    );
}
export default JobCard;