import FlexRowGroup from "../flex/FlexRowGroup";
import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Label from "../form/Label";
import Button from "../form/Button";
import { useNavigate } from "react-router-dom";
function FourthSection(){
  const navigate = useNavigate();
    return(
        <FlexRowGroup className={"fourthSection"}>
        <FlexColGroup className={"content"}>
          <FlexColGroup className={"info"}>
            <Label className={"title"}>
                Unlocking Potential : Building <span>Sustainability</span>
            </Label>
            <Label className={"description"}>
            As an innovative electronics startup, Unio Labs is committed to promoting sustainability and environmental stewardship in all aspects of our business. We recognize the importance of protecting our planet and reducing our carbon footprint, and we strive to incorporate sustainable practices into our operations and products.
            </Label>
          </FlexColGroup>
          <FlexColGroup className={'content'}>
                <Button className={'see-more-button'} onClick={()=>{
                  navigate('/sustainability')
                }}>
                    <span>See more</span>
                </Button>
            </FlexColGroup>
        </FlexColGroup>
  
        <Image alt={"Second Section Image"} className={"image"} />
      </FlexRowGroup> 
    )
}
export default FourthSection;