import FlexColGroup from "../components/flex/FlexColGroup";
import FirstSection from "../components/gallery/FirstSection";
import "../assets/css/gallery.css";
import SecondSection from "../components/gallery/SecondSection";
import LastSection from "../components/discuss/LastSection";
function Gallery(){
    return(
        <FlexColGroup className={'gallery'}>
            <FirstSection/>
            <SecondSection/>
            <LastSection/>
        </FlexColGroup>
    )
}
export default Gallery;