import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";
import Button from "../form/Button";
import Image from "../form/Image";
import BackIcon from "../../assets/images/aboutus/SixthSection/arrow_left.svg";
import ForwardIcon from "../../assets/images/aboutus/SixthSection/arrow_right.svg";
import AvatarImage from "../../assets/images/aboutus/SixthSection/Avatar_Image.png";
import { useState } from "react";

function SixthSection(){
    const [selectedIndex,setSelectedIndex] = useState(0);
    const reviews = [
        {
            "id" : 1,
            "title" : "I recently had the pleasure of working with Unio Labs and I must say, they exceeded my expectations. The quality of their work is exceptional and they were very attentive to detail. I am more than satisfied with the result and I'm planning to move all my future projects to Unio Labs. Thank you for providing an excellent service!",
            "companyName" : "Position, Company name",
            "name" : "Julian Swift",
            "avatar" : AvatarImage
        },
        {
            "id" : 2,
            "title" : "I had a great experience working with Unio Labs. They were very professional and knowledgeable in electronics and programming, taking the time to understand my needs before getting to work. They were also very fluent in English and open to corrections and modifications without any hassle."
            ,"companyName" : "Position, Company name",
            "name" : "Eugenio Arroyo",
            "avatar" : AvatarImage
        },
        {
            "id" : 3,
            "title" : "After spending several weeks getting nowhere with a previous coder on and Arduino based LED program, working with Unio Labs was a complete breath of fresh air!  They immediately understood the project and delivered it quickly and made tweaks where requested. I can fully recommend working with Unio labs!",
            "companyName" : "Position, Company name",
            "name" : "Lee R.",
            "avatar" : AvatarImage
        }
    ];
    const renderedReview = reviews.map((element)=>{
            return (
                <FlexColGroup className={'review'}>
                        <Label className={'title'}>{element.title}</Label>
                        <FlexColGroup className={'avatar'}>
                            {/* <Image className={'image'} src={element.avatar}/> */}
                            <FlexColGroup className={'info'}>
                                <Label className={'name'}>{element.name}</Label>
                                {/* <Label className={'companyName'}>
                                {element.companyName}
                                </Label> */}
                            </FlexColGroup>
                        </FlexColGroup>
                    </FlexColGroup>
            )
    })

    const renderedButton = reviews.map((element,index)=>{
        return (
            <div className={`button ${(index===selectedIndex)? 'selected': ''}`}></div>
        )
    });
    const onHandleBackClick = () =>{
        if(selectedIndex !== 0){
            setSelectedIndex(selectedIndex-1)
        }
    }
    const onHandleForwardClick = () =>{
        if(selectedIndex !== reviews.length-1){
            setSelectedIndex(selectedIndex+1)
        }
    }
    return(
        <FlexColGroup className={'sixthSection'}>
            <FlexColGroup className={'content'}>
                <FlexRowGroup className={'top'}>
                    <div className="margin"></div>
                    <Label className={'title'}>Check out what our clients have to say about us!</Label>
                </FlexRowGroup>
            </FlexColGroup>
            <FlexColGroup className={'reviews'}>
                <FlexRowGroup className={'main'}>
                    <Button className={'controls left'} onClick={()=>{onHandleBackClick()}}>
                        <Image className={'icon'} src={BackIcon}/>
                    </Button>
                        <div className="slideshowSlider" style={{ transform: `translate( ${-selectedIndex * 30}%, 0)` }}>
                        {renderedReview}
                        </div>
                    <Button className={'controls right'} onClick={()=>{onHandleForwardClick()}}>
                        <Image className={'icon'} src={ForwardIcon}/>
                    </Button>
                </FlexRowGroup>
                <FlexRowGroup className={'buttons'}>
                    {renderedButton}
                </FlexRowGroup>
            </FlexColGroup>
        </FlexColGroup>
    )
}
export default SixthSection;