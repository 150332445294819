import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Label from "../form/Label";
import DownIcon from "../../assets/images/career/Icon.svg";
import FlexRowGroup from "../flex/FlexRowGroup";
import { useState } from "react";
function AccordianQuestion({question}){
    const [isExpand,setIsExpand] = useState(false);
    return (
        <FlexColGroup className={'accordian-question'}>
        <FlexRowGroup className={'questions-contains'}>
            <Label className={'question-text'}>{question.question}</Label>
            <Image className={`arrow ${(isExpand)?'inverse':''}`} src={DownIcon} onClick={(()=>{
                setIsExpand(!isExpand)
            })}/>
        </FlexRowGroup>
        <FlexColGroup className={`answer ${(isExpand)?'expands':''}`}>
                <Label className={'answer-text'}>{question.answer}</Label>
            </FlexColGroup>
        </FlexColGroup>
     )
}
export default AccordianQuestion;