import Career from "./pages/Career";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Team from "./pages/Team";
import Gallery from "./pages/Gallery";
import Culture from "./pages/Culture";
import Portfolio from "./pages/Portfolio"
import Sustainability from "./pages/Sustainability";
import Innovation from "./pages/Innovation";
import Industry from "./pages/Industry";
import Service from "./pages/Service";
import Navbar from "./pages/Navbar";
import Footer from "./pages/Footer";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DigitalAgriculture from "./pages/Industry/DigitalAgriculture";
import Manufacturing from "./pages/Industry/Manufacturing";
import HealthCare from "./pages/Industry/HealthCare";
import HVAC from "./pages/Industry/HVAC";
import EnergyUtilites from "./pages/Industry/EnergyUtilites";
import ArtificalIntelligence from "./pages/Service/ArtificalIntelligence";
import EmbeddedSystem from "./pages/Service/EmbeddedSystem";
import Iot from "./pages/Service/Iot";
import IntegratedEngineeringSolution from "./pages/Service/IntegratedEngineeringSolution";
import SecurityAndPerformanceEngineering from "./pages/Service/SecurityAndPerformanceEngineering";
import PreventionAndPredictionMaintanence from "./pages/Service/PreventionAndPredictionMaintanence";
import DigitalTwinning from "./pages/Service/DigitalTwinning";
import ComputerVisionBasedSmartInspection from "./pages/Service/ComputerVisionBasedSmartInspection";
import AssetTracking from "./pages/Industry/AssetTracking";
import Logistics from "./pages/Industry/Logistics";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Cookies from "./pages/Cookies";
import Loading from "./components/loading/Loading";
import { useState } from "react";
import { useEffect } from "react";
function App() {
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false)
    },2800)
  },[]);

  return (
    <div className="App">
      {
       (!loading) ? 
        <>
    <BrowserRouter>
        <Navbar/>     
        <div className="mainlayout">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/industry">
          <Route path="/industry/digital-Agriculture" element={<DigitalAgriculture/>}/>
          <Route path="/industry/manufacturing" element={<Manufacturing/>}/>
          <Route path="/industry/healthcare" element={<HealthCare/>}/>
          <Route path="/industry/hvac" element={<HVAC/>}/>
          <Route path="/industry/energy-Utilites" element={<EnergyUtilites/>}/>
          <Route path="/industry/asset-tracking" element={<AssetTracking/>}/>
          <Route path="/industry/logistics" element={<Logistics/>}/>

        </Route>
        <Route path="/services">
          <Route path = "/services/artifical-intelligence" element={<ArtificalIntelligence/>}/>
          <Route path = "/services/embedded-system" element={<EmbeddedSystem/>}/>
          <Route path = "/services/internet-of-things" element={<Iot/>}/>
          <Route path = "/services/security-and-performance-engineering" element={<SecurityAndPerformanceEngineering/>}/>
          <Route path = "/services/integrated-engineering-solutions" element={<IntegratedEngineeringSolution/>}/>
          <Route path = "/services/computer-vision-based-smart-inspection" element={<ComputerVisionBasedSmartInspection/>}/>
          <Route path = "/services/digital-twinning" element={<DigitalTwinning/>}/>
          <Route path = "/services/predictive-and-preventive-maintenance" element={<PreventionAndPredictionMaintanence/>}/>
        </Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
        <Route path="/cookie-policy" element={<Cookies/>}></Route>

        <Route path="/sustainability" element={<Sustainability/>}></Route>
        <Route path="/terms-and-condition" element={<TermsAndCondition  />}></Route>
        <Route path="/innovation" element={<Innovation />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/portfolio">
        <Route path="/portfolio" element={<Portfolio/>}/>
        <Route path="/portfolio/gallery" element={<Gallery/>  }/>
        </Route>
        <Route path="/people">
          <Route path = "/people/team" element={<Team/>}/>
          <Route path = "/people/culture" element={<Culture/>}/>
        </Route>
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/career" element={<Career />} />
      </Routes>
      </div>
      <Footer/>
    </BrowserRouter>
      </>: <Loading/>
      }
    </div>
  );
}

export default App;
