import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import { useEffect } from "react";
import Label from "../form/Label";

function FirstSection() {
  useEffect(() => {
    window.scrollTo(0,0);
  },[]);
  return (
    <FlexColGroup className="firstSection">
      <FlexColGroup className={"content"}>
        <FlexColGroup className={'top'}>
          <div className="margin"></div>
          <Label className={"title"}>
          Leading the Way :<br/> Our Latest Development          
          </Label>
        </FlexColGroup>
        <Label className={"description"}>
        We refers to the cutting-edge innovation that we have recently achieved or implemented. It is the result of our dedication to pushing the boundaries of what is possible and constantly striving to improve and evolve.
        </Label>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default FirstSection;
