import FlexColGroup from "../flex/FlexColGroup";
import Label from "../form/Label";

function FirstSection(){
    return(
      <FlexColGroup className={'firstSection'}>
        <FlexColGroup className={"content"}>
          <FlexColGroup className={'main'}>
            <div className="margin"></div>
            <Label className={"title"}>
            Unleash Your Potential : Explore Exciting Career Opportunities !          
            </Label>
          </FlexColGroup>
      </FlexColGroup>
      </FlexColGroup>
    )
}
export default FirstSection;