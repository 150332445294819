import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Label from "../form/Label";

function Card({card}){
    const {image,title,description,bgcolor} = card;
    return (
        <FlexColGroup className={'card'} style={{backgroundColor : bgcolor}}>
            <Image className={'image'} src={image}/>
            <Label className={'title'}>
                {title}
            </Label>
            <Label className={'description'}>
                {description}
            </Label>
        </FlexColGroup>
    )
}
export default Card;