import FlexColGroup from "../flex/FlexColGroup";
import Image from "../form/Image";
import Image1 from "../../assets/images/culture/image1.png";
import Image2 from "../../assets/images/culture/image2.png";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";
import Card from "./Card";
import InfoCard from "./InfoCard";

function SecondSection() {
  const card = [{
    title : 'Diversity and Inclusion :',
    image : require('../../assets/images/culture/Cards/Card1.png'),
    description : 'We believe that diversity is a strength, and we strive to build a workplace that is inclusive and welcoming to people from all backgrounds. We are committed to creating an environment where everyone feels valued, respected, and able to contribute their unique perspectives and experiences.',
    bgcolor : '#0A1128'
  },{
    title : 'Innovation :',
    image : require('../../assets/images/culture/Cards/Card2.png'),
    description : `We are always pushing the boundaries of what is possible, using the latest technologies and creative problem-solving to find new solutions to old problems. We believe that innovation is the key to success in today's fast-paced world, and we are dedicated to staying at the forefront of technological development.`,
    bgcolor : '#181C11'
  },{
    title : 'Collaboration :',
    image : require('../../assets/images/culture/Cards/Card3.png'),
    description : 'We believe that the best ideas come from working together, and we encourage open communication and collaboration at all levels of our organization. We strive to create a culture of teamwork and mutual support, where everyone is empowered to share their ideas and work together to achieve our goals.',
    bgcolor : '#1B0903'
  },{
    title : 'Work-Life Balance :',
    image : require('../../assets/images/culture/Cards/Card4.png'),
    description : 'We understand that our employees have lives outside of work, and we believe in supporting their well-being and work-life balance. We offer flexible work arrangements and prioritize the health and well-being of our employees, our employees are the key to a successful and sustainable company.',
    bgcolor : '#1B0315'
  }]
  const infoCard = [{
    title : 'Holistic Problem-Solving :',
    description : 'We provide assistance in managing their supply chain. Unio Labs can help businesses optimize their supply chain, reduce costs, and ensure timely delivery of products.',
    number : '01'
  },{
    title : 'Focus on Learning :',
    description : 'We encourage continuous learning and development, both personally and professionally, through training programs, mentorship, and hands-on experience.',
    number : '02'
  },{
    title : 'Sustainability as a Core Value :',
    description : 'At Unio Labs, we believe in using technology to create a better future for all.  Our solutions are not only innovative and efficient but also sustainable, helping our clients to reduce their carbon footprint and create a more sustainable future for all.',
    number : '03'
  }
]
const renderedCards = card.map((element)=>{
  return(
    <Card card={element}/>
  )
})
const renderedInfoCards = infoCard.map((element)=>{
  return(
    <InfoCard infoCard={element} />
  )
})
  return (
    <FlexColGroup className={"secondSection"}>
      <FlexRowGroup className={"images"}>
        <Image src={Image2}></Image>
        <Image src={Image1}></Image>
      </FlexRowGroup>
      <FlexRowGroup className={"main"}>
        <Image alt={"Second Section Image"} className={"image"} />
        <FlexColGroup className={"content"}>
        <div className="margin"></div>
          <FlexColGroup className={"info"}>
            <Label className={"title"}>
            Our Culture of Excellence and Empowerment
            </Label>
            <Label className={"description"}>
            At Unio Labs, we believe that our people are our greatest asset. We are committed to building a dynamic and inclusive workplace culture that values diversity, collaboration, and innovation.
            </Label>
          </FlexColGroup>
        </FlexColGroup>
      </FlexRowGroup>
      <FlexColGroup className={'things'}>
        <FlexRowGroup className={'header'}>
            <div className="margin"></div>
            <Label className={'title'}>Things we value</Label>
        </FlexRowGroup>
        <FlexRowGroup className={'cards'}>
              {renderedCards}
        </FlexRowGroup>
      </FlexColGroup>
      <FlexColGroup className={'things'}>
      <FlexRowGroup className={'header'}>
            <div className="margin"></div>
            <Label className={'title'}>What makes us different</Label>
        </FlexRowGroup>
        <FlexColGroup className={'info-cards'}>
         {renderedInfoCards}
        </FlexColGroup>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default SecondSection;
