import FlexColGroup from "../../components/flex/FlexColGroup";
import FirstSection from "../../components/service/FirstSection";
import SecondSection from "../../components/service/SecondSection";
import "../../assets/css/industry.css";
import ThirdSection from "../../components/service/ThirdSection";
import ForthSection from "../../components/service/ForthSection";
import FifthSection from "../../components/service/FifthSection";
import SixthSection from "../../components/service/SixthSection";
import FirstSectionImage from "../../assets/images/service/Embedded System/firstSection.png";
import LastSection from "../../components/discuss/LastSection";
function EmbeddedSystem(){
    const card = {
        title : 'Embedded Systems',
        description : "Embedded systems are an integral part of modern technology, powering everything from smartphones to industrial automation equipment. At Unio Labs, we specialize in developing embedded systems for a wide range of applications, including consumer electronics, automotive systems, medical devices, and more.Our team of expert engineers has extensive experience in developing embedded systems from the ground up, from concept design to product launch. We work closely with our clients to understand their unique requirements and develop custom solutions that meet their specific needs.",
        isLeft : false,
        image : require('../../assets/images/service/Embedded System/secondSection.png')
    }
    const infoCard = [{
        title: 'Embedded Hardware Design',
        description: `We offers Embedded Hardware Design services to help businesses develop custom hardware solutions for their products. This includes designing circuit boards, selecting components, and testing prototypes to ensure high-quality and reliability.`,
        number: '01'
    },{
        title: 'Firmware Development',
        description: 'We provides Firmware Development services for embedded systems, ensuring that the system is running smoothly and efficiently. This includes writing code, testing, and debugging software to ensure that the system is functioning correctly.',
        number: '02'
    },{
        title: 'IoT and Sensor Integration',
        description: 'We provides IoT and Sensor Integration services for embedded systems, enabling businesses to monitor and control their devices remotely. This includes integrating sensors and actuators, designing communication protocols, and developing cloud-based dashboards for data visualization and analysis.',
        number: '03'
    },{
        title: 'System Testing and Validation',
        description: 'We offers System Testing and Validation services for embedded systems, ensuring that the system meets the desired performance and quality requirements. This includes functional testing, performance testing, and regulatory compliance testing, ensuring that the system meets industry standards and regulations.',
        number: '04'
    }]
    const benefitCards = [
        {
            number : '01',
            text : "Customized Solutions: Unio Labs' Embedded Systems services are tailored to each customer's unique needs, ensuring that the final product is customized to their specific requirements. This results in a high-quality, reliable solution that meets the customer's needs and expectations."
        },
        {
            number : '02',
            text : 'Cost-Effective Solutions: By working closely with customers, Unio Labs is able to develop solutions that are both efficient and cost-effective. This helps customers save money while still getting a high-quality product that meets their needs.'
        },{
            number : '03',
            text :"Faster Time to Market: Unio Labs' expertise in Embedded Systems development enables them to provide customers with a faster time to market for their products. This helps customers stay ahead of their competitors and seize new opportunities in their markets."
        },{
            number : '04',
            text : "Quality Assurance: Unio Labs provides comprehensive testing and validation services to ensure that the final product meets the highest standards of quality and reliability. This helps customers avoid costly recalls and customer dissatisfaction."
        },{
            number : '05',
            text : "Expertise and Experience: Unio Labs has a team of expert engineers with extensive experience in Embedded Systems development. This ensures that customers receive the best possible solutions, with the latest technologies and industry standards. Customers can rely on Unio Labs' expertise and experience to deliver a solution that meets their needs and exceeds their expectations."
        }
    ]
    return(
        <FlexColGroup className={'service'}>
            <FirstSection title={'Innovative and Simple Solutions for Complex Industrial and Workshop Problems'} legendTitle={'Services / Embedded Systems'} description={'Customized Embedded Systems Design and Development'} image={FirstSectionImage}/>
            <SecondSection card={card}/>
            <ThirdSection infoCard={infoCard} 
            title={'Unlock the Potential with Our Comprehensive Services'}
            description={'Our offerings in Embedded Systems'}    
            />
            <ForthSection />
            <SixthSection benefitCards={benefitCards} />
            <LastSection/>
        </FlexColGroup>
    )
}
export default EmbeddedSystem;