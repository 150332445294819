import FirstSection from "../components/aboutus/FirstSection";
import "../assets/css/aboutus.css";
import FlexColGroup from "../components/flex/FlexColGroup";
import SecondSection from "../components/aboutus/SecondSection";
import FourthSection from "../components/aboutus/FourthSection";
import SeventhSection from "../components/aboutus/SeventhSection";
import FifthSection from "../components/aboutus/FifthSection";
import SixthSection from "../components/aboutus/SixthSection";
import { useEffect } from "react";
import LastSection from "../components/discuss/LastSection";
import ThirdSection from "../components/aboutus/ThirdSection";
function AboutUs(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return(
        <FlexColGroup className={'aboutus'}>
        <FirstSection/>
        <SecondSection/>
        <ThirdSection/>
        <FourthSection/>
        <FifthSection/>
        {/* <SixthSection/> */}
        <LastSection/>
        </FlexColGroup>
    )
}
export default AboutUs;