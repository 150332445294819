import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";

function InfoCard({infoCard}){
    const {number,title,description} = infoCard;
    return (
        <FlexRowGroup className={'info-card'}>
            <Label className={'info-number'}>{number}</Label>
            <FlexColGroup className={'info-content'}>
                <Label className={'info-title'}>{title}</Label>
                <Label className={'info-description'}>{description}</Label>
            </FlexColGroup>
        </FlexRowGroup>
    );
}
export default InfoCard