import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Image from "../form/Image";
import SixthSectionImage from "../../assets/images/home/sixthSection.svg";
import Label from "../form/Label";
import Button from "../form/Button";
import "../../assets/css/lastSection.css"
import { useNavigate } from "react-router-dom";
function LastSection() {
    const navigate = useNavigate();
    return (
        <FlexRowGroup className={'lastSection'}>
            <Image className={'image'} />
            <FlexColGroup className={'content'}>
                <Label className={'title'}>Empower Your Business With Us</Label>
                <Label className={'description'}>Get in touch to discover how we can help</Label>
                <Button className={'hover-button'} onClick={() => {
                    navigate('/contact-us')
                }}>
                    <span>Let's Discuss</span>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_836_28083" style={{ "mask-type": "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="27">
                            <rect y="0.5" width="26" height="26" />
                        </mask>
                        <g mask="url(#mask0_836_28083)">
                            <path d="M15.1668 20L13.6502 18.4292L17.496 14.5833H4.3335V12.4167H17.496L13.6502 8.57083L15.1668 7L21.6668 13.5L15.1668 20Z" />
                        </g>
                    </svg>
                </Button>
                
            </FlexColGroup>
        </FlexRowGroup>
    )
}
export default LastSection;