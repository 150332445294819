import FlexColGroup from "../flex/FlexColGroup";
import FlexRowGroup from "../flex/FlexRowGroup";
import Label from "../form/Label";

function FirstSection() {
  return (
    <FlexColGroup className="firstSection">
      <FlexColGroup className={"content"}>
        <FlexColGroup className={'top'}>
          <div className="margin"></div>
          <Label className={"title"}>
          Our People, Our Culture          
          </Label>
          <Label className={'description'}>
          Fostering a Dynamic and Inclusive Workplace at Unio Labs
          </Label>
        </FlexColGroup>
      </FlexColGroup>
    </FlexColGroup>
  );
}
export default FirstSection;
